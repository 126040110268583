import React from "react";
import SuspenseComp from "../../components/shared/SuspenseComp";
import { getPathFromRouter } from "../../helper";
import { SkeletonPagePolaris } from "../../components/shared/SkeletonPagePolaris";

const ArtistTaskComp = React.lazy(() =>
  import("../../components/tasks/artist/ArtistTask"),
);

export default function ArtistTaskPage(...props) {
  const path = getPathFromRouter(props);
  return (
    <SuspenseComp fallback={<SkeletonPagePolaris />}>
      <ArtistTaskComp path={path} />
    </SuspenseComp>
  );
}
