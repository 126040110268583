import { CaretRightOutlined } from "@ant-design/icons";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Card, Collapsible, Spinner, Stack, TextStyle } from "@shopify/polaris";
import { gql } from "apollo-boost";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import { formatAggregations, handleError } from "../../../helper";
import { EmptyStatePolaris } from "../../shared/EmptyStatePolaris";
import { useReportsContext } from "../context";
import { ComparedPercent } from "./ComparedPercent";
import { useCollapseContext } from "./LayoutSection";

const GET_TASKS_V2 = gql`
    query tasksV2($filter: TaskFilter) {
        tasksV2(filter: $filter) {
            total
            aggregations
        }
    }
`;

const collapseKey = "tasks";

const LABELS = {
    all: "All Tasks",
    pendingDesign: "Pending design",
    assigned: "Assigned",
    unassigned: "Un-assigned",
    pending: "Pending",
    doing: "Doing",
    fileGenerating: "File Generating",
    needReview: "Need review",
    rejected: "Rejected",
    expiredSoon: "Expired soon",
    denied: "Denied",
    expired: "Expired",
    cancel: "Cancel",
    done: "Done",
};

export function Tasks({ assigneeIds }) {
    // Context
    const {
        range,
        isCompare,
        rangeToCompare,
        loading: loadingRoot,
    } = useReportsContext();
    const { collapseActive = {}, setCollapseActive = () => {} } =
        useCollapseContext() || {};
    const open = !!collapseActive[collapseKey];

    const btnRef = useRef(null);

    // State
    const [filter, setFilter] = useState({
        fieldByTime: null,
        isIdeaTask: false,
        isArtistTask: false,
        assigneeIds,
    });
    const [state, setState] = useState({
        items: [],
        total: 0,
    });
    const [stateCompare, setStateCompare] = useState({
        total: 0,
    });
    const [mergedLoading, setMergedLoading] = useState(true);

    // Queries
    const { data, loading, error } = useQuery(GET_TASKS_V2, {
        variables: {
            filter,
        },
    });

    const [
        lazyReport,
        { data: dataC, loading: loadingC, error: errorC },
    ] = useLazyQuery(GET_TASKS_V2);

    // Get data
    useEffect(() => {
        const mergedLoading = loading || loadingC || loadingRoot;
        setMergedLoading(mergedLoading);
    }, [loading, loadingC, loadingRoot]);

    useEffect(() => {
        setFilter((prev) => ({
            ...prev,
            fieldByTime:
                range == null
                    ? undefined
                    : {
                          field: "tasks.created_at",
                          ...range,
                      },
        }));
    }, [range]);

    useEffect(() => {
        const agg = data?.tasksV2?.aggregations;
        if (agg != null) {
            const formattedAgg = formatAggregations(agg);

            const sortAgg = {};
            sortAgg.all = formattedAgg.all;
            sortAgg.pendingDesign = formattedAgg.pendingDesign;
            sortAgg.assigned = formattedAgg.assigned;
            sortAgg.unassigned = formattedAgg.unassigned;
            sortAgg.pending = formattedAgg.pending;
            sortAgg.doing = formattedAgg.doing;
            sortAgg.fileGenerating = formattedAgg.fileGenerating;
            sortAgg.needReview = formattedAgg.needReview;
            sortAgg.rejected = formattedAgg.rejected;
            sortAgg.expiredSoon = formattedAgg.expiredSoon;
            sortAgg.denied = formattedAgg.denied;
            sortAgg.expired = formattedAgg.expired;
            sortAgg.cancel = formattedAgg.cancel;
            sortAgg.done = formattedAgg.done;

            const result = Object.entries(sortAgg).reduce(
                (acc, [key, value]) => {
                    const item = {
                        label: LABELS[key],
                        value,
                    };

                    return LABELS[key] != null ? acc.concat(item) : acc;
                },
                []
            );

            setState((prev) => ({
                ...prev,
                items: result,
                total: sortAgg.all,
            }));
        }
    }, [data]);

    useEffect(() => {
        if (isCompare) {
            let fieldByTime = null;
            if (rangeToCompare?.from != null) {
                fieldByTime = {
                    ...filter.fieldByTime,
                    ...rangeToCompare,
                    field: "tasks.created_at",
                };
            }
            lazyReport({
                variables: {
                    filter: {
                        ...filter,
                        fieldByTime,
                    },
                },
            });
        }
    }, [rangeToCompare, filter, isCompare]);

    useEffect(() => {
        if (isCompare) {
            const total = dataC?.tasksV2?.total;
            setStateCompare((prev) => ({
                ...prev,
                total,
            }));
        } else {
            setStateCompare({ total: 0 });
        }
    }, [dataC, isCompare]);

    const handleToggle = useCallback(() => {
        setCollapseActive((p) => ({ ...p, [collapseKey]: !p[collapseKey] }));
    }, [collapseKey, setCollapseActive]);
    const handleTriggerBtn = useCallback(() => {
        btnRef.current && btnRef.current.click();
    }, []);

    // Markup
    const contentMarkup = useMemo(() => {
        return state.items.map((item, index) => (
            <Stack key={`task-${index}`}>
                <Stack.Item fill>{item.label}</Stack.Item>
                <span>{item.value}</span>
            </Stack>
        ));
    }, [state.items]);
    const headMarkup = (
        <Stack>
            <Stack.Item fill>
                <TextStyle variation="strong">Name</TextStyle>
            </Stack.Item>
            <TextStyle variation="strong">Count</TextStyle>
        </Stack>
    );
    const subTitle = useMemo(
        () => (
            <span>
                <span>{state.total} Tasks</span>
                <ComparedPercent
                    originalVal={state.total}
                    newVal={stateCompare.total}
                />
            </span>
        ),
        [state.total, stateCompare.total]
    );
    const headerMarkup = useMemo(
        () => (
            <Stack spacing="loose">
                <Stack.Item fill>
                    <HeadingWrap>
                        <span className="btn-wrap">
                            <CaretRightOutlined
                                rotate={open ? 90 : 0}
                                style={{ color: "#000" }}
                                ref={btnRef}
                                onClick={handleToggle}
                            />
                        </span>
                        <h2
                            className="Polaris-Heading"
                            onClick={handleTriggerBtn}
                        >
                            Tasks
                        </h2>
                    </HeadingWrap>
                </Stack.Item>
                <span>{subTitle}</span>
            </Stack>
        ),
        [subTitle]
    );

    const mergedError = error || errorC;
    return (
        <Card>
            <Card.Header title={headerMarkup} />
            <Card.Section>
                <Collapsible
                    open={open}
                    id={collapseKey}
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                >
                    {mergedLoading ? (
                        <Stack distribution="center">
                            <Spinner size="small" />
                        </Stack>
                    ) : mergedError ? (
                        <div>Error: {handleError(error.toString())}</div>
                    ) : contentMarkup ? (
                        <>
                            <Stack vertical spacing="loose">
                                {headMarkup}
                                {contentMarkup}
                            </Stack>
                        </>
                    ) : (
                        <EmptyStatePolaris noData />
                    )}
                </Collapsible>
            </Card.Section>
        </Card>
    );
}

const HeadingWrap = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -1.4rem;

    .Polaris-Heading {
        cursor: pointer;
    }

    .btn-wrap {
        margin-top: 0.2rem;
        margin-right: 0.5rem;
    }
`;
