import { gql } from "apollo-boost";
import {
  TaskFragment,
  IdeaTaskFragment,
  TaskBaseMockupFragment,
  TaskBaseSubmissionFragment,
  FileFragment,
} from "../fragments";

export const MARK_PSD_IN_NAS = gql`
  mutation markProductHasPsdInNas($productVariantId: ID!) {
    markProductHasPsdInNas(productVariantId: $productVariantId)
  }
`;

export const UPDATE_RESOURCE_TASK = gql`
  mutation updateResourceTask($taskId: ID!, $description: String!) {
    updateResourceTask(taskId: $taskId, description: $description) {
      id
      description
    }
  }
`;

export const ACCEPT_DESIGN = gql`
  mutation acceptDesign($id: ID!) {
    acceptDesign(id: $id)
  }
`;

export const FORCE_CANCEL_TASK = gql`
  mutation forceCancelTask($id: ID!) {
    forceCancelTask(id: $id)
  }
`;

export const DESIGNER_DENY_TASK = gql`
  mutation designerDenyTask($taskIds: [ID!]) {
    designerDenyTask(taskIds: $taskIds)
  }
`;

export const DESIGNER_START_TASK = gql`
  mutation designerStartTask($taskIds: [ID!]) {
    designerStartTask(taskIds: $taskIds)
  }
`;

export const BULK_UPDATE_TASK = gql`
  mutation bulkUpdateTask($id: [ID!]!, $input: BulkEditTask) {
    bulkUpdateTask(id: $id, input: $input) {
      id
      orderId
      productId
      personalized
      teamId
      requirement
      status
      kpi
    }
  }
`;

export const UPDATE_DESIGN_TASK = gql`
  mutation updateDesignTask($input: EditDesignTask!) {
    updateDesignTask(input: $input) {
      id
      orderId
      productId
      personalized
      teamId
      requirement
      status
      kpi
    }
  }
`;

export const SET_TASK_STATUS_TO_DOING = gql`
  mutation setTaskStatusToDoing($taskIds: [ID!]) {
    setTaskStatusToDoing(taskIds: $taskIds)
  }
`;

export const RE_ASSIGN_TASK = gql`
  mutation reAssignTask($input: EditTaskDenied!) {
    reAssignTask(input: $input) {
      ...TaskFragment
    }
  }
  ${TaskFragment}
`;

export const UPDATE_PRODUCT_PSD_FILES = gql`
  mutation updateProductPSDFiles($productId: ID!, $files: [ID!], $psd: String) {
    updateProductPSDFiles(productId: $productId, files: $files, psd: $psd)
  }
`;

export const REJECT_DESIGN = gql`
  mutation rejectDesignV2($input: EditTaskSubmission!) {
    rejectDesignV2(input: $input)
  }
`;

export const UPLOAD_DESIGN_TASK = gql`
  mutation uploadDesignTask($input: NewUploadDesignTask!) {
    uploadDesignTask(input: $input) {
      id
      assigneeId
      status
    }
  }
`;

export const CREATE_DESIGN_TASK = gql`
  mutation createDesignTask($input: NewDesignTask!) {
    createDesignTask(input: $input) {
      ...TaskFragment
    }
  }
  ${TaskFragment}
`;

export const DESIGNER_CREATE_TASK_FROM_ORDER_SELECTED = gql`
  mutation designerCreateTaskFromOrderSelected($orderId: ID!) {
    designerCreateTaskFromOrderSelected(orderId: $orderId)
  }
`;

export const UPDATE_KPI = gql`
  mutation updateKPI($orderId: ID!, $kpi: Float!) {
    updateKPI(orderId: $orderId, kpi: $kpi)
  }
`;

export const BULK_ACCEPT_DESIGN = gql`
  mutation bulkAcceptDesign($id: [ID!]!) {
    bulkAcceptDesign(id: $id) {
      id
      status
      log
    }
  }
`;

export const BULK_REJECT_DESIGN = gql`
  mutation bulkRejectDesignV2(
    $ids: [ID!]!
    $rejectReason: String!
    $rejectResources: [ID!]
  ) {
    bulkRejectDesignV2(
      ids: $ids
      rejectReason: $rejectReason
      rejectResources: $rejectResources
    )
  }
`;

export const MARK_AWAITING_APPROVAL = gql`
  mutation markAwaitingApproval(
    $taskId: ID!
    $isWaiting: Boolean!
    $note: String
  ) {
    markAwaitingApproval(taskId: $taskId, isWaiting: $isWaiting, note: $note)
  }
`;

export const MARK_PRODUCT_CANNOT_CONFIG_IN_PB = gql`
  mutation markProductCannotConfigInPB($productId: ID!, $note: String) {
    markProductCannotConfigInPB(productId: $productId, note: $note) {
      id
      isCannotConfigInPB
      cannotConfigInPBNote
    }
  }
`;

export const REMOVE_PRODUCT_CANNOT_CONFIG_IN_PB = gql`
  mutation removeFlagProductCannotConfigInPB($productId: ID!) {
    removeFlagProductCannotConfigInPB(productId: $productId) {
      id
      isCannotConfigInPB
      cannotConfigInPBNote
    }
  }
`;

export const CREATE_IDEA_TASK = gql`
  mutation createIdeaTask($input: NewIdeaTask!) {
    createIdeaTask(input: $input) {
      ...TaskFragment
    }
  }
  ${TaskFragment}
`;

export const EDIT_IDEA_TASK = gql`
  mutation editIdeaTask($input: EditIdeaTask!) {
    editIdeaTask(input: $input) {
      ...TaskFragment
    }
  }
  ${TaskFragment}
`;

export const ASSIGN_IDEA_TASK_TO_DESIGNER = gql`
  mutation assignIdeaTaskToDesigner($input: IdeaTaskAssignToDesign!) {
    assignIdeaTaskToDesigner(input: $input) {
      id
      assigneeId
      kpi
      assignee {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DENY_IDEA_TASK = gql`
  mutation denyIdeaTask($id: ID!) {
    denyIdeaTask(id: $id) {
      id
      status
    }
  }
`;

export const START_IDEA_TASK = gql`
  mutation startIdeaTask($id: ID!) {
    startIdeaTask(id: $id) {
      id
      status
    }
  }
`;

export const CANCEL_IDEA_TASK = gql`
  mutation cancelIdeaTask($id: ID!) {
    cancelIdeaTask(id: $id) {
      id
      status
    }
  }
`;

export const GEN_MOCKUP_FOR_IDEA_TASK = gql`
  mutation genMockupForIdeaTask($input: NewUploadDesignIdeaTask!) {
    genMockupForIdeaTask(input: $input) {
      ...IdeaTaskFragment
    }
  }
  ${IdeaTaskFragment}
`;

export const SEND_REVIEW_IDEA_TASK = gql`
  mutation sendReviewIdeaTask($id: ID!) {
    sendReviewIdeaTask(id: $id) {
      ...IdeaTaskFragment
    }
  }
  ${IdeaTaskFragment}
`;

export const REVIEW_IDEA_TASK = gql`
  mutation reviewIdeaTask($input: ReviewDesignIdeaTask!) {
    reviewIdeaTask(input: $input) {
      id
      status
    }
  }
`;

export const RE_GEN_MOCKUP_FOR_IDEA_TASK = gql`
  mutation reGenMockupForIdeaTask($input: ReGenMockupForIdeaTask!) {
    reGenMockupForIdeaTask(input: $input) {
      ...TaskBaseMockupFragment
    }
  }
  ${TaskBaseMockupFragment}
`;

export const SELLER_UPDATE_KPI_IDEA_TASK = gql`
  mutation sellerUpdateKpiIdeaTask($id: ID!, $kpi: Float!) {
    sellerUpdateKpiIdeaTask(id: $id, kpi: $kpi) {
      id
    }
  }
`;

export const CREATE_CUSTOM_MOCKUP_FOR_IDEA_TASK = gql`
  mutation createCustomMockupForIdeaTask(
    $taskBaseSubmissionID: ID!
    $fileIds: [ID!]!
  ) {
    createCustomMockupForIdeaTask(
      taskBaseSubmissionID: $taskBaseSubmissionID
      fileIds: $fileIds
    ) {
      ...TaskBaseSubmissionFragment
    }
  }
  ${TaskBaseSubmissionFragment}
`;

export const CREATE_TASK_BASE_MOCKUP_FOR_SUBMISSION = gql`
  mutation createTaskBaseMockupForSubmission(
    $originTemplateIds: [Int!]
    $taskBaseSubmissionId: ID!
  ) {
    createTaskBaseMockupForSubmission(
      originTemplateIds: $originTemplateIds
      taskBaseSubmissionId: $taskBaseSubmissionId
    ) {
      ...TaskBaseMockupFragment
    }
  }
  ${TaskBaseMockupFragment}
`;

export const IDEA_LEADER_PUBLISH_IDEA_TASK = gql`
  mutation ideaLeaderPublishIdeaTask($id: ID!, $kpi: Float!) {
    ideaLeaderPublishIdeaTask(id: $id, kpi: $kpi) {
      ...IdeaTaskFragment
    }
  }
  ${IdeaTaskFragment}
`;

export const DELETE_IDEA_TASK = gql`
  mutation deleteIdeaTask($id: ID!) {
    deleteIdeaTask(id: $id)
  }
`;

export const DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION = gql`
  mutation deleteTaskBaseMockupOfSubmission(
    $originTemplateId: Int!
    $taskBaseSubmissionId: ID!
  ) {
    deleteTaskBaseMockupOfSubmission(
      originTemplateId: $originTemplateId
      taskBaseSubmissionId: $taskBaseSubmissionId
    )
  }
`;

export const BULK_DELETE_TASK_BASE_MOCKUP_OF_SUBMISSION = gql`
  mutation bulkDeleteTaskBaseMockupOfSubmission(
    $originTemplateIds: [Int!]!
    $taskBaseSubmissionId: ID!
  ) {
    bulkDeleteTaskBaseMockupOfSubmission(
      originTemplateIds: $originTemplateIds
      taskBaseSubmissionId: $taskBaseSubmissionId
    )
  }
`;

export const SET_MAIN_TASK_BASE_MOCKUP = gql`
  mutation setMainTaskBaseMockup($id: ID!) {
    setMainTaskBaseMockup(id: $id) {
      ...TaskBaseMockupFragment
    }
  }
  ${TaskBaseMockupFragment}
`;

export const IDEA_LEADER_DECLINE_IDEA_TASK = gql`
  mutation ideaLeaderDeclineIdeaTask($id: ID!, $reason: String) {
    ideaLeaderDeclineIdeaTask(id: $id, reason: $reason) {
      id
      status
    }
  }
`;

export const IDEA_MARKER_SUBMIT_TO_DRAFT = gql`
  mutation ideaMakerSubmitToDraft($id: ID!) {
    ideaMakerSubmitToDraft(id: $id) {
      id
      status
    }
  }
`;

export const UPDATE_PSD_FILE_TASK_BASE_GROUP = gql`
  mutation updatePsdFileTaskBaseGroup($input: [EditPsdFileTaskBaseGroup!]!) {
    updatePsdFileTaskBaseGroup(input: $input) {
      id
      psd
      psdFiles {
        ...FileFragment
      }
    }
  }
  ${FileFragment}
`;

export const PASS_TASK_TO_NEED_REVIEW = gql`
  mutation passTaskToNeedReview($taskID: ID!) {
    passTaskToNeedReview(taskID: $taskID)
  }
`;

export const PASS_TASK_TO_NEW_STATUS = gql`
  mutation passTaskToNewStatus($taskID: ID!, $newStatus: String!) {
    passTaskToNewStatus(taskID: $taskID, newStatus: $newStatus)
  }
`;

export const EXPORT_DESIGN_URL = gql`
  mutation exportDesignURL($taskIDs: [ID!]!) {
    exportDesignURL(taskIDs: $taskIDs)
  }
`;

export const BULK_PASS_TASK_TO_NEW_STATUS = gql`
  mutation bulkPassTaskToNewStatus($taskIDs: [ID!]!, $newStatus: String!) {
    bulkPassTaskToNewStatus(taskIDs: $taskIDs, newStatus: $newStatus)
  }
`;

export const MARK_AS_TASK_CONFIGURABLE = gql`
  mutation markAsTaskConfigurable($taskIDs: [ID!]!) {
    markAsTaskConfigurable(taskIDs: $taskIDs) {
      taskID
    }
  }
`;

export const REMOVE_TASK_FROM_CONFIGURABLE = gql`
  mutation removeTaskFromConfigurable($taskIDs: [ID!]!) {
    removeTaskFromConfigurable(taskIDs: $taskIDs) {
      taskID
    }
  }
`;

export const ASSIGN_IDEA_TASK_TO_DESIGN_TEAM = gql`
  mutation assignIdeaTaskToDesignTeam($input: IdeaTaskAssignToDesignTeam!) {
    assignIdeaTaskToDesignTeam(input: $input)
  }
`;

export const RE_ASSIGN_DESIGN_TEAM_FOR_IDEA_TASK = gql`
  mutation reAssignDesignTeamForIdeaTask(
    $input: ReAssignDesignTeamForIdeaTask!
  ) {
    reAssignDesignTeamForIdeaTask(input: $input)
  }
`;

export const ADDON_APPROVAL_TASK = gql`
  mutation addonApprovalTask($input: [AddonAcceptTaskInput!]!) {
    addonApprovalTask(input: $input)
  }
`;

export const ADDON_REJECT_TASK = gql`
  mutation addonRejectTask($input: [AddonRejectTaskInput!]!) {
    addonRejectTask(input: $input)
  }
`;

export const UPDATE_TASK_KPI = gql`
  mutation updateTaskKPI($taskID: ID!, $newKpi: Float!) {
    updateTaskKPI(taskID: $taskID, newKpi: $newKpi) {
      id
      status
    }
  }
`;

// Artist Task
export const UPDATE_ARTIST_TASK = gql`
  mutation updateArtistTask($input: EditArtistTask!) {
    updateArtistTask(input: $input) {
      id
      status
    }
  }
`;

export const ARTIST_START_TASK = gql`
  mutation artistStartTask($taskIds: [ID!]) {
    artistStartTask(taskIds: $taskIds)
  }
`;

export const UPLOAD_FILE_TO_ARTIST_TASK = gql`
  mutation uploadFileToArtistTask($input: NewUploadArtistTask! ) {
    uploadFileToArtistTask(input: $input) {
      id
      status
    }
  }
`;

export const REJECT_ARTIST_FILES = gql`
  mutation rejectArtistFiles($input: EditArtistTaskSubmission!) {
    rejectArtistFiles(input: $input) 
  }
`;

export const ACCEPT_ARTIST_FILES = gql`
  mutation acceptArtistFiles($taskIds: [ID!]) {
    acceptArtistFiles(taskIds: $taskIds)
  }
`;

export const UPDATE_REJECTED_ARTIST_TASK = gql`
  mutation updateRejectedArtistTask($input: EditArtistTask!) {
    updateRejectedArtistTask(input:$input) {
      id
      status
    }
  }
`;

export const ARTIST_DENY_TASK = gql`
  mutation artistDenyTask($taskIds: [ID!]) {
    artistDenyTask(taskIds: $taskIds)
  }
`;
