import { Spinner } from "@shopify/polaris";
import React from "react";

function SuspenseComp({ children, fallback }) {
  let fb = fallback;

  if (typeof fallback === "undefined") {
    fb = <Spinner size="small" />;
  }

  return <React.Suspense fallback={fb}>{children}</React.Suspense>;
}

export default SuspenseComp;
