import MainLayout from "../layout/MainLayout";
import ArtistTaskPage from "../pages/seller/ArtistTaskPage";

const routes = [
  {
    path: "/artist-leader/artist-tasks",
    exact: true,
    component: ArtistTaskPage,
    title: "Artist Task page",
    layout: MainLayout,
    roles: ["Seller"],
    teamRole: "Artist Leader",
  },
];

export default routes;
