import admin from "./admin";
import seller from "./seller";
import shared from "./shared";
import supplier from "./supplier";
import storeManager from "./storeManager";
import marketplaceManager from "./marketplaceManager";
import idea from "./idea";
import ideaLeader from "./ideaLeader";
import sellerSupporter from "./seller-supporter";
import adminSupporter from "./admin-supporter";
import designer from "./designer";
import designLeader from "./design-leader";
import sellerSupplier from "./seller-supplier";
import sellerAccountant from "./seller-accountant";
import artistRoutes from "./artist";
import artistLeaderRoutes from "./artist-leader";

export default [
  ...admin,
  ...seller,
  ...supplier,
  ...shared,
  ...storeManager,
  ...marketplaceManager,
  ...idea,
  ...ideaLeader,
  ...sellerSupporter,
  ...adminSupporter,
  ...designer,
  ...designLeader,
  ...sellerSupplier,
  ...sellerAccountant,
  ...artistRoutes,
  ...artistLeaderRoutes,
];
