import { useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  Checkbox,
  ChoiceList,
  Collapsible,
  Filters,
  Stack,
  TextField,
} from "@shopify/polaris";
import { gql } from "apollo-boost";
import { get } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { KPI } from "../../constants/task";
import { useAppContext } from "../../context";
import {
  GET_DESIGN_ONLINE_TEAMS,
  GET_NICHES,
  GET_USER_BY_ROLES,
} from "../../graphql/queries";
import {
  arrInvalid,
  checkRole,
  formatDataTree,
  genLabelTree,
  isEmpty,
} from "../../helper";
import {
  COLLECTIONS_QUERY,
  TAGS_QUERY,
} from "../../pages/seller/ProductFiltersPolaris";
import { PLATFORMS, TEAM_ROLE } from "../../variable";
import { PRODUCT_BASES } from "../base/ProductBaseSelectPolaris";
import { FilterHasSearchMVPolaris } from "../filters/FilterHasSearchMVPolaris";
import { FilterHasSearchPolaris } from "../filters/FilterHasSearchPolaris";
import { FilterListRadioPolaris } from "../filters/FilterListRadioPolaris";
import { FilterNoSearchMVPolaris } from "../filters/FilterNoSearchMVPolaris";
import { FilterNoSearchPolaris } from "../filters/FilterNoSearchPolaris";
import { FilterOrderByPolaris } from "./FilterOrderByPolaris";
import { FilterSortOrderPolaris } from "./FilterSortOrderPolaris";
import { FilterTimeByPolaris } from "./FilterTimeByPolaris";

const QUERY_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      nodes {
        id
        title
        domain
        email
        platform
        owners {
          id
          user {
            id
            email
          }
        }
      }
    }
  }
`;

const Draft = "Draft";
const All = "All";
const Pending = "Pending";
const Doing = "Doing";
const Rejected = "Rejected";
const Denied = "Denied";
const Cancel = "Cancel";
const Done = "Done";
const PendingDesign = "Pending Design";
const ExpiredSoon = "Expired Soon";
const Expired = "Expired";
const PartiallyCompleted = "Partially Completed";
const FileGenerating = "File Generating";
const NeedReview = "Need Review";
const Declined = "Declined";
const NeedBuyerConfirm = "Need Buyer Confirm";
const AwaitingBuyerConfirm = "Awaiting Buyer Confirm";
const ArtworkConfigurable = "Artwork Configurable";

export const AssignToMe = "Assign To Me";

export const STATUS_OPTIONS = [
  { value: All, label: All },
  { value: PendingDesign, label: PendingDesign },
  { value: Draft, label: Draft },
  { value: Pending, label: Pending },
  { value: ArtworkConfigurable, label: ArtworkConfigurable },
  { value: Doing, label: Doing },
  { value: FileGenerating, label: "Mockup Generating" },
  { value: NeedBuyerConfirm, label: NeedBuyerConfirm },
  { value: AwaitingBuyerConfirm, label: AwaitingBuyerConfirm },
  { value: NeedReview, label: NeedReview },
  { value: Rejected, label: Rejected },
  { value: ExpiredSoon, label: ExpiredSoon },
  { value: Denied, label: Denied },
  { value: Expired, label: Expired },
  { value: Cancel, label: Cancel },
  { value: PartiallyCompleted, label: PartiallyCompleted },
  { value: Done, label: Done },
];
const DECLINED_ELEMENT = { value: Declined, label: Declined };
const IDEA_TASK_EXCLUDES = [
  ExpiredSoon,
  Expired,
  PendingDesign,
  ArtworkConfigurable,
];
const DESIGN_TASK_EXCLUDES = [Draft, PartiallyCompleted, Declined];
const ARTIST_TASK_EXCLUDES = [
  Draft,
  ArtworkConfigurable,
  PartiallyCompleted,
  FileGenerating,
  NeedBuyerConfirm,
  AwaitingBuyerConfirm,
  ExpiredSoon,
];

export const IDEA_TASK_INCLUDES = STATUS_OPTIONS.map((i) => i.value).filter(
  (i) => !IDEA_TASK_EXCLUDES.includes(i),
);
export const DESIGN_TASK_INCLUDES = STATUS_OPTIONS.map((i) => i.value).filter(
  (i) => !DESIGN_TASK_EXCLUDES.includes(i),
);

export const ARTIST_TASK_INCLUDES = STATUS_OPTIONS.map((i) => i.value).filter(
  (i) => !ARTIST_TASK_EXCLUDES.includes(i),
);

let ORDER_SOURCE_OPTIONS = [];

for (let [key, value] of Object.entries(PLATFORMS)) {
  ORDER_SOURCE_OPTIONS.push({ label: key, value });
}

const ordersCreatedAt = "orders.created_at";
const ORDER_BY_OPTIONS = [
  { value: "tasks.created_at", label: "Task Created Date" },
  { value: ordersCreatedAt, label: "Order Created Date" },
  { value: "tasks.kpi", label: "KPI" },
  { value: "tasks.deadline", label: "Deadline" },
  { value: "tasks.done_date", label: "Done Date" },
  { value: "tasks.rejected_at", label: "Rejected Date" },
];

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
  .domain {
    font-size: 0.9em;
    padding-right: 5px;
    color: var(--p-interactive, #006fbb);
  }
`;

const ORDER_TYPE = [
  { value: "All", label: "All" },
  { value: "normal", label: "Normal" },
  { value: "personalized", label: "Personalized" },
];

const HAS_DESIGN = [
  { value: "All", label: "All" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const YES = "Yes";

const BULK_ACCEPT = [
  { value: All, label: All },
  { value: YES, label: YES },
];
const USING_EXPRESS_SHIPPING = [
  { value: All, label: All },
  { value: YES, label: YES },
];

const APP_DESIGN_ACTION = [
  { value: "All", label: "All" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const PRODUCT_CREATED_VIA_OPTIONS = [
  { value: All, label: All },
  { value: "IdeaTask", label: "IdeaTask" },
  // { value: "Crawled", label: "Crawled" },
];

const KPI_OPTIONS =
  KPI?.length > 0 ? KPI.map((i) => ({ value: i, label: i.toString() })) : [];
KPI_OPTIONS.unshift({ value: "All", label: "All" });

export const FilterControlPolaris = ({
  onChangeFilter,
  isDesigner,
  isLeader,
  hasStoreFilter,
  filters: {
    assigneeIds,
    status: propsStatus,
    orderBy: propsOrderBy,
    order: propsOrder,
    hasAssign,
    platform,
    search,
    productBaseId,
    storeIds,
    personalized,
    hasDesign: hasDesignProp,
    usingDesignApp: usingDesignAppProp,
    kpi: kpiProp,
    usingTaskBulkDesign: filterBulkAccept,
    usingExpressShipping,
    createdById,
    productCreatedVia: productCreatedViaProp,
    similarTaskAssigneeId,
    hasPBArtwork: hasPBArtworkProp,
    designOnlineTeamID: designOnlineTeamIDProp,
  },
  isIdeaTask,
  ideaRoles,
}) => {
  // Context
  const { currentUser } = useAppContext();
  const {
    isSeller,
    isDesignLeader,
    isOnline: isDesignOnline,
  } = checkRole(currentUser);

  // Props
  const showSimilarTaskAssignees =
    (isSeller || isDesignLeader) &&
    propsStatus === "Pending Design" &&
    !hasAssign;
  const NEW_STATUS_OPTIONS = useMemo(() => {
    let result = STATUS_OPTIONS;
    if (isIdeaTask) {
      result = result.filter((i) => !IDEA_TASK_EXCLUDES.includes(i.value));

      if (!ideaRoles) {
        result = result.filter((i) => ![Draft].includes(i.value));
      } else if (isDesigner) {
        result = result.filter((i) => ![PendingDesign].includes(i.value));

        const newDT = [...result];
        const indexDenied = newDT.findIndex((i) => i.value === Denied);
        if (indexDenied > -1) {
          newDT.splice(indexDenied, 0, DECLINED_ELEMENT);
        }
        result = newDT;
      }
    } else {
      result = result.filter((i) => !DESIGN_TASK_EXCLUDES.includes(i.value));
    }

    return result;
  }, [isIdeaTask, ideaRoles, isDesigner]);

  const ORDER_BY_OPTIONS2 = useMemo(() => {
    return isIdeaTask
      ? ORDER_BY_OPTIONS.filter((i) => i.value !== ordersCreatedAt)
      : ORDER_BY_OPTIONS;
  }, [isIdeaTask]);

  // State
  const [queryValue, setQueryValue] = useState(search);
  const [designer, setDesigner] = useState({
    value: [],
    label: [],
    search: null,
  });
  const [dataDesigner, setDataDesigner] = useState([]);
  const [taskAssignee, setTaskAssignee] = useState({
    value: similarTaskAssigneeId,
    label: null,
    search: null,
  });
  const [dataDesigner2, setDataDesigner2] = useState([]);

  const [status, setStatus] = useState({
    value: propsStatus,
    label: null,
  });
  const [filterTime, setFilterTime] = useState({
    range: null,
    rangeLabel: null,
    field: "tasks.created_at",
    fieldLabel: null,
  });
  const [orderBy, setOrderBy] = useState({
    value: propsOrderBy,
    label: null,
  });
  const [order, setOrder] = useState(propsOrder);
  const [stateAssign, setStatusAssign] = useState(isDesigner ? false : null);
  const [orderSource, setOrderSource] = useState({
    // value: platform,
    value: [],
    label: [],
  });

  const [productBase, setProductBase] = useState({
    value: productBaseId || [],
    label: [],
    search: null,
  });
  const [productBases, setProductBases] = useState([]);

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState({
    value: [],
    label: [],
    search: null,
  });

  // Order type
  const [orderType, setOrderType] = useState({
    value: null,
    label: null,
  });

  // Has design
  const [hasDesign, setHasDesign] = useState({
    value: null,
    label: null,
  });
  const [kpi, setKpi] = useState({
    value: null,
    label: null,
  });
  const [rangeKpi, setRangeKpi] = useState({ value: null, label: null });

  const [bulkAcceptDesign, setBulkAcceptDesign] = useState({
    value: null,
    label: null,
  });
  const [usingDesignApp, setUsingDesignApp] = useState({
    value: null,
    label: null,
  });
  const [hasPBArtwork, setHasPBArtwork] = useState({
    value: null,
    label: null,
  });
  const [productCreatedVia, setProductCreatedVia] = useState({
    value: null,
    label: null,
  });

  // usingExpressShipping
  const [usingES, setUsingES] = useState({
    value: null,
    label: null,
  });

  const [collections, setCollections] = useState([]);
  const [collection, setCollection] = useState({
    value: [],
    label: [],
    search: null,
  });

  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState({
    value: [],
    label: [],
    search: null,
  });

  const [niches, setNiches] = useState([]);
  const [niche, setNiche] = useState({
    value: [],
    label: [],
    search: null,
  });

  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState({
    value: createdById,
    label: null,
    search: null,
  });

  const typingTimeoutRef = useRef(null);

  const [designOnlineTeams, setDesignOnlineTeams] = useState([]);
  const [designOnlineTeam, setDesignOnlineTeam] = useState({
    value: designOnlineTeamIDProp,
    label: null,
    search: null,
  });

  const [selectAllDesigner, setSelectAllDesigner] = useState(false);
  const [selectDesignerFiltered, setSelectDesignerFiltered] = useState(false);

  // ========================== QUERIES ==========================
  const {
    data: dataD,
    loading: loadingD,
    error: errorD,
  } = useQuery(GET_USER_BY_ROLES, {
    variables: {
      roles: [TEAM_ROLE.Designer],
    },
  });

  const {
    data: dataD2,
    loading: loadingD2,
    error: errorD2,
  } = useQuery(GET_USER_BY_ROLES, {
    variables: {
      roles: [TEAM_ROLE.Designer],
    },
    skip: !showSimilarTaskAssignees,
  });

  const {
    data: dataDOT,
    loading: loadingDOT,
    error: errorDOT,
  } = useQuery(GET_DESIGN_ONLINE_TEAMS, {
    variables: {
      limit: 20,
      offset: 0,
      search: designOnlineTeam.search,
    },
  });

  React.useEffect(() => {
    const nodes = dataDOT?.getDesignOnlineTeams?.nodes || [];
    const opts = nodes.map((item) => ({ value: item.id, label: item.name }));
    setDesignOnlineTeams(opts);

    if (designOnlineTeamIDProp) {
      const match = opts.find((i) => i.value === designOnlineTeamIDProp);
      if (match) {
        setDesignOnlineTeam((p) => ({
          ...p,
          value: match.value,
          label: match.label,
        }));
      }
    }
  }, [dataDOT, designOnlineTeamIDProp]);

  const { data: dataPB } = useQuery(PRODUCT_BASES, {
    skip: isDesigner,
  });

  const {
    data: dataStore,
    loading: loadingStore,
    error: errorStore,
    fetchMore,
    networkStatus,
  } = useQuery(QUERY_STORES, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: store.search,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: isDesigner,
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: dataCollection,
    loading: loadingCollection,
    error: errorCollection,
  } = useQuery(COLLECTIONS_QUERY, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: collection.search,
      },
    },
    skip: !isIdeaTask,
  });

  const {
    data: dataTag,
    loading: loadingTag,
    error: errorTag,
  } = useQuery(TAGS_QUERY, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: tag.search,
      },
    },
    skip: !isIdeaTask,
  });
  const {
    data: dataNiche,
    loading: loadingNiche,
    error: errorNiche,
  } = useQuery(GET_NICHES, {
    variables: {
      filter: {
        limit: 20,
        offset: 0,
        search: niche.search,
      },
    },
    skip: !isIdeaTask,
  });

  const {
    data: dataAuthor,
    loading: loadingAuthor,
    error: errorAuthor,
  } = useQuery(GET_USER_BY_ROLES, {
    variables: {
      roles: [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.IdeaLeader,
        TEAM_ROLE.Idea,
      ],
    },
    skip: !isIdeaTask,
  });

  // ========================== GET DATA ==========================
  useEffect(() => {
    const nodes =
      dataCollection?.collections?.nodes?.length > 0
        ? dataCollection.collections.nodes
        : [];
    const newCollections = formatDataTree(nodes);
    setCollections(newCollections);
  }, [dataCollection]);

  useEffect(() => {
    const nodes = dataTag?.tags?.nodes?.length > 0 ? dataTag.tags.nodes : [];
    const newTags = formatDataTree(nodes);
    setTags(newTags);
  }, [dataTag]);

  useEffect(() => {
    const nodes =
      dataNiche?.niches?.nodes?.length > 0 ? dataNiche.niches.nodes : [];
    const newTags = formatDataTree(nodes);
    setNiches(newTags);
  }, [dataNiche]);

  useEffect(() => {
    let newData =
      dataAuthor?.listUserTeamByRoles?.length > 0
        ? dataAuthor.listUserTeamByRoles
            .filter((i) => i.user)
            .filter(Boolean)
            .map(({ user }) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))
        : [];
    setAuthors(newData);

    if (createdById) {
      if (newData.length === 0) return;
      let currentAuthor = newData.find((i) => i.value === createdById);

      if (currentAuthor) {
        setAuthor((prev) => ({
          ...prev,
          label: currentAuthor.label,
          value: currentAuthor.value,
        }));
      }
    }
  }, [dataAuthor, createdById]);

  //

  useEffect(() => {
    if (true === filterBulkAccept) {
      setBulkAcceptDesign({
        value: YES,
        label: YES,
      });
    }
  }, [filterBulkAccept]);

  useEffect(() => {
    if (productCreatedViaProp) {
      const curVal = PRODUCT_CREATED_VIA_OPTIONS.find(
        (i) => i.value === productCreatedViaProp,
      );
      if (curVal) {
        setProductCreatedVia(curVal);
      }
    }
  }, [productCreatedViaProp]);

  useEffect(() => {
    if (usingExpressShipping) {
      setUsingES({
        value: YES,
        label: YES,
      });
    }
  }, [usingExpressShipping]);

  useEffect(() => {
    if (true === personalized) {
      setOrderType((prevState) => ({
        ...prevState,
        value: "personalized",
        label: "Personalized",
      }));
    }
    if (false === personalized) {
      setOrderType((prevState) => ({
        ...prevState,
        value: "normal",
        label: "Normal",
      }));
    }
  }, [personalized]);
  useEffect(() => {
    if (true === hasDesignProp) {
      setHasDesign((prevState) => ({
        ...prevState,
        value: "Yes",
        label: "Yes",
      }));
    }
    if (false === hasDesignProp) {
      setHasDesign((prevState) => ({
        ...prevState,
        value: "No",
        label: "No",
      }));
    }
  }, [hasDesignProp]);

  useEffect(() => {
    if (true === usingDesignAppProp) {
      setUsingDesignApp((prevState) => ({
        ...prevState,
        value: "Yes",
        label: "Yes",
      }));
    }
    if (false === usingDesignAppProp) {
      setUsingDesignApp((prevState) => ({
        ...prevState,
        value: "No",
        label: "No",
      }));
    }
  }, [usingDesignAppProp]);

  useEffect(() => {
    if (true === hasPBArtworkProp) {
      setHasPBArtwork((prevState) => ({
        ...prevState,
        value: "Yes",
        label: "Yes",
      }));
    }
    if (false === hasPBArtworkProp) {
      setHasPBArtwork((prevState) => ({
        ...prevState,
        value: "No",
        label: "No",
      }));
    }
  }, [hasPBArtworkProp]);

  useEffect(() => {
    if (kpiProp) {
      let currentKpi = KPI_OPTIONS.find((i) => i.value === kpiProp);
      if (currentKpi) {
        setKpi(currentKpi);
      }
    } else {
      setKpi({ value: null, label: null });
    }
  }, [kpiProp]);

  useEffect(() => {
    if (dataStore) {
      let newData =
        dataStore?.stores?.nodes?.length > 0
          ? dataStore.stores.nodes.map((i) => {
              let subTitle = i.domain;
              subTitle = subTitle.replace("https://", "");
              subTitle = subTitle.replace("http://", "");

              if (i.platform && ["ebay", "amazon"].includes(i.platform)) {
                subTitle = i.email;
              }
              return {
                value: i.id,
                // label: i.title,
                label: (
                  <LabelContainer>
                    <span className="title">{i.title}</span>
                    <span className="domain">{subTitle}</span>
                  </LabelContainer>
                ),
              };
            })
          : [];
      setStores(newData);
      if (!arrInvalid(storeIds)) {
        let currentStore = newData.filter((i) => storeIds.includes(i.value));
        if (!arrInvalid(arrInvalid)) {
          setStore((prevState) => ({
            ...prevState,
            value: currentStore.map(({ value }) => value),
            label: currentStore.map(({ label }) => label),
          }));
        }
      }
    }
  }, [dataStore, storeIds]);

  useEffect(() => {
    let newData =
      dataD?.listUserTeamByRoles?.length > 0
        ? dataD.listUserTeamByRoles
            .filter((i) => i.user)
            .filter(Boolean)
            .map(({ user }) => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`,
            }))
        : [];
    setDataDesigner(newData);

    if (!arrInvalid(assigneeIds)) {
      let currentD =
        newData?.length > 0
          ? newData.filter((i) => assigneeIds.includes(i.value))
          : null;
      if (!arrInvalid(currentD)) {
        setDesigner((prev) => ({
          ...prev,
          label: currentD.map(({ label }) => label),
          value: currentD.map(({ value }) => value),
        }));
      }
    }
  }, [dataD, assigneeIds]);

  useEffect(() => {
    if (!showSimilarTaskAssignees) {
      setTaskAssignee({ value: null, label: null });
      return;
    }

    if (
      !dataD2 ||
      !dataD2.listUserTeamByRoles ||
      dataD2.listUserTeamByRoles.length === 0
    ) {
      return;
    }

    let newData = dataD.listUserTeamByRoles
      .filter((i) => i.user)
      .filter(Boolean)
      .map(({ user }) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
    setDataDesigner2(newData);

    if (similarTaskAssigneeId) {
      let currentD =
        newData && newData.length > 0
          ? newData.find((i) => i.value === similarTaskAssigneeId)
          : null;
      if (currentD) {
        setTaskAssignee((prev) => ({
          ...prev,
          label: currentD.label,
          value: currentD.value,
        }));
      }
    }
  }, [dataD2, similarTaskAssigneeId, showSimilarTaskAssignees]);

  useEffect(() => {
    let newData =
      dataPB?.productBasesForSeller?.nodes?.length > 0
        ? dataPB.productBasesForSeller.nodes.map((pb) => ({
            value: pb.id,
            label: pb.title,
          }))
        : [];
    setProductBases(newData);

    if (productBaseId) {
      let propsPB = productBaseId;
      let currentPB = newData.filter((i) => propsPB.includes(i.value));

      let ids = currentPB?.length > 0 ? currentPB.map((i) => i.value) : [];
      let labels = currentPB?.length > 0 ? currentPB.map((i) => i.label) : [];

      if (currentPB?.length) {
        setProductBase((prevState) => ({
          ...prevState,
          value: ids,
          label: labels,
        }));
      }
    }
  }, [dataPB, productBaseId]);

  useEffect(() => {
    setStatusAssign(hasAssign);
  }, [hasAssign]);

  useEffect(() => {
    if (propsStatus) {
      let currentS = NEW_STATUS_OPTIONS.find((i) => i.value === propsStatus);
      if (currentS) {
        setStatus({
          value: currentS.value,
          label: currentS.label,
        });
      }
    } else {
      setStatus({
        value: null,
        label: null,
      });
    }
  }, [propsStatus, NEW_STATUS_OPTIONS]);

  useEffect(() => {
    if (platform) {
      // let currentP = ORDER_SOURCE_OPTIONS.find((i) => i.value === platform);
      let currentP = ORDER_SOURCE_OPTIONS.filter((i) =>
        platform.includes(i.value),
      );
      let ids = currentP?.length > 0 ? currentP.map((i) => i.value) : [];
      let labels = currentP?.length > 0 ? currentP.map((i) => i.label) : [];

      if (currentP?.length > 0) {
        setOrderSource((prev) => ({
          ...prev,
          value: ids,
          label: labels,
        }));
      }
    }
  }, [platform]);

  useEffect(() => {
    if (propsOrderBy) {
      let currenOB = ORDER_BY_OPTIONS2.find((i) => i.value === propsOrderBy);
      if (currenOB) {
        setOrderBy((prev) => ({
          ...prev,
          label: currenOB.label,
          value: currenOB.value,
        }));
      }
    }
  }, [propsOrderBy, ORDER_BY_OPTIONS2]);

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    let convertType = orderType.value;
    if ("normal" === convertType) {
      convertType = false;
    }
    if ("personalized" === convertType) {
      convertType = true;
    }

    let convertHD = hasDesign.value;
    if ("No" === convertHD) {
      convertHD = false;
    }
    if ("Yes" === convertHD) {
      convertHD = true;
    }

    let convertUAD = usingDesignApp.value;
    if ("No" === convertUAD) {
      convertUAD = false;
    }
    if ("Yes" === convertUAD) {
      convertUAD = true;
    }
    let convertPBA = hasPBArtwork.value;
    if ("No" === convertPBA) {
      convertPBA = false;
    }
    if ("Yes" === convertPBA) {
      convertPBA = true;
    }

    let convertAD = bulkAcceptDesign.value;
    if ("Yes" === convertAD) {
      convertAD = true;
    }

    const covertES = usingES.value === YES ? true : null;

    let filterIdeaTask = {};

    if (isIdeaTask) {
      filterIdeaTask = {
        collections: genTaxonomy(collection.value),
        tags: genTaxonomy(tag.value),
        niches: genTaxonomy(niche.value),
        createdById: author.value,
      };
    }

    const convertProductCreatedVia = productCreatedVia.value;

    if (onChangeFilter) {
      let valuesUpdates = {
        kpiRange: null,
        kpi: null,
      };

      if (kpi.value) {
        valuesUpdates.kpiRange = null;
        valuesUpdates.kpi = kpi.value;
      } else if (rangeKpi.value) {
        valuesUpdates.kpiRange = rangeKpi.value;
        valuesUpdates.kpi = null;
      }

      typingTimeoutRef.current = setTimeout(() => {
        onChangeFilter({
          designer: designer.value?.length > 0 ? designer.value : null,
          similarTaskAssigneeId: taskAssignee.value,
          search: queryValue ? queryValue.trim() : queryValue,
          status: status.value,
          storeIds: store.value?.length > 0 ? store.value : null,
          order: order,
          orderBy: orderBy.value,
          stateAssign: stateAssign,
          filterTime: {
            from: filterTime.range && filterTime.range.from,
            to: filterTime.range && filterTime.range.to,
            field: filterTime.field,
          },
          orderSource: orderSource.value,
          productBaseId:
            productBase.value && productBase.value.length > 0
              ? productBase.value
              : null,
          personalized: convertType,
          hasDesign: convertHD,
          usingDesignApp: convertUAD,
          hasPBArtwork: convertPBA,
          // kpi: kpi.value,
          usingTaskBulkDesign: convertAD,
          usingExpressShipping: covertES,
          productCreatedVia: convertProductCreatedVia,
          designOnlineTeamID: designOnlineTeam.value || null,
          ...filterIdeaTask,
          ...valuesUpdates,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    designer.value,
    queryValue,
    status,
    order,
    orderBy,
    filterTime,
    stateAssign,
    orderSource,
    productBase,
    store.value,
    orderType.value,
    hasDesign.value,
    usingDesignApp.value,
    kpi.value,
    bulkAcceptDesign.value,
    usingES.value,
    taskAssignee.value,
    hasPBArtwork.value,

    // Idea Task
    isIdeaTask,
    collection.value,
    tag.value,
    niche.value,
    author.value,
    productCreatedVia.value,
    designOnlineTeam.value,
    rangeKpi.value,
  ]);

  // ========================== ACTIONS ==========================
  const handleUpdateState = useCallback((newState, setState) => {
    setState((prev) => ({ ...prev, ...newState }));
  }, []);

  const handleCollectionChange = useCallback(
    (newState) => {
      handleUpdateState(newState, setCollection);
    },
    [handleUpdateState],
  );

  const handleCollectionSearch = useCallback(
    (search) => {
      handleUpdateState({ search }, setCollection);
    },
    [handleUpdateState],
  );

  const handleCollectionRemove = useCallback(
    () => handleUpdateState({ value: [], label: [] }, setCollection),
    [handleUpdateState],
  );

  const handleTagChange = useCallback(
    (newState) => {
      handleUpdateState(newState, setTag);
    },
    [handleUpdateState],
  );

  const handleTagSearch = useCallback(
    (search) => {
      handleUpdateState({ search }, setTag);
    },
    [handleUpdateState],
  );

  const handleTagRemove = useCallback(
    () => handleUpdateState({ value: [], label: [] }, setTag),
    [handleUpdateState],
  );

  const handleNicheChange = useCallback(
    (newState) => {
      handleUpdateState(newState, setNiche);
    },
    [handleUpdateState],
  );

  const handleNicheSearch = useCallback(
    (search) => {
      handleUpdateState({ search }, setNiche);
    },
    [handleUpdateState],
  );

  const handleNicheRemove = useCallback(
    () => handleUpdateState({ value: [], label: [] }, setNiche),
    [handleUpdateState],
  );

  const handleAuthorChange = useCallback(
    (newState) => {
      handleUpdateState(newState, setAuthor);
    },
    [handleUpdateState],
  );

  const handleDesignerChange = useCallback(
    ({ value, label }) => {
      setDesigner((prev) => ({ ...prev, value, label }));
    },
    [handleUpdateState, dataDesigner],
  );

  const handleAuthorSearch = useCallback(
    (newState) => {
      handleUpdateState(newState, setAuthor);
    },
    [handleUpdateState],
  );

  const handleAuthorRemove = useCallback(
    () => handleUpdateState({ value: null, label: null }, setAuthor),
    [handleUpdateState],
  );

  const handleDesignOnlineTeamRemove = useCallback(() => {
    handleUpdateState({ value: null, label: null }, setDesignOnlineTeam);
  }, [handleUpdateState]);

  // ===
  const handleFilterQueryChange = useCallback(
    (value) => setQueryValue(value),
    [],
  );

  const handleDesignerRemove = useCallback(() => {
    setDesigner({ value: [], label: [] });
    setSelectAllDesigner(false);
    setSelectDesignerFiltered(false);
  }, []);

  const handleTaskAssigneeRemove = useCallback(
    () => setTaskAssignee({ value: null, label: null }),
    [],
  );
  const handleProductBaseRemove = useCallback(
    () => setProductBase({ value: [], label: [], search: null }),
    [],
  );
  const handleStatusRemove = useCallback(() => {
    let newStatus = { value: null, label: null };
    if (isDesigner && isDesignOnline) {
      if (status.value === "Pending") {
        return;
      } else {
        newStatus = { value: "Pending", label: "Pending" };
      }
    }

    setStatus(newStatus);
    setStatusAssign(null);
  }, [isDesigner, isDesignOnline, status.value]);

  const handleStoreRemove = useCallback(
    () => setStore(() => ({ value: [], label: [], search: null })),
    [],
  );
  const handleFilterTimeRemove = useCallback(
    () =>
      setFilterTime({
        range: null,
        rangeLabel: null,
        field: null,
        fieldLabel: null,
      }),
    [],
  );
  const handleOrderByRemove = useCallback(
    () => setOrderBy({ value: null, label: null }),
    [],
  );
  const handleOrderRemove = useCallback(() => setOrder(null), []);
  const handleOrderSourceRemove = useCallback(
    () => setOrderSource({ value: [], label: [] }),
    [],
  );
  const handleQueryRemove = useCallback(() => setQueryValue(null), []);

  // Handle remove
  const handleOrderTypeRemove = useCallback(
    () => setOrderType(() => ({ value: null, label: null })),
    [],
  );

  const handleRangeKpiRemove = useCallback(() => {
    setRangeKpi(() => ({ value: null, label: null }));
  }, []);

  const handleKpiRemove = useCallback(() => {
    setKpi(() => ({ value: null, label: null }));
    handleRangeKpiRemove();
  }, [handleRangeKpiRemove]);

  const handleHasDesign = useCallback(
    () => setHasDesign({ value: null, label: null }),
    [],
  );
  const handleUsingDesignAppRemove = useCallback(
    () => setUsingDesignApp({ value: null, label: null }),
    [],
  );
  const handleHasPBArtworkRemove = useCallback(
    () => setHasPBArtwork({ value: null, label: null }),
    [],
  );
  const handleBulkAcceptDesignRemove = useCallback(
    () => setBulkAcceptDesign({ value: null, label: null }),
    [],
  );

  const handleProduceCreatedViaRemove = useCallback(() => {
    setProductCreatedVia({ value: null, label: null });
  }, []);

  const handleUsingESRemove = useCallback(
    () => setUsingES({ value: null, label: null }),
    [],
  );

  const handleKpiChange = useCallback(
    ({ value, label }) => {
      setKpi((prevState) => ({
        ...prevState,
        value,
        label,
      }));
      handleRangeKpiRemove();
    },
    [handleRangeKpiRemove],
  );

  const handleRangeKpiChange = useCallback(
    (values) => {
      handleKpiRemove();

      const newVal = { from: 0, to: 10 };
      if (values.from) {
        newVal.from = Number.parseFloat(values.from);
      }

      if (values.to) {
        newVal.to = Number.parseFloat(values.to);
      }

      let label = `from ${newVal.from} to ${newVal.to}`;
      setRangeKpi({ value: newVal, label });
    },
    [handleKpiRemove],
  );

  const handleSelectAllDesigner = useCallback(
    (val) => {
      setSelectAllDesigner(val);
      if (!val) {
        handleDesignerRemove();
        return;
      }

      const { value, label } = getAllDesigner(dataDesigner);
      setDesigner((p) => ({
        ...p,
        value,
        label,
      }));

      setSelectDesignerFiltered(true);
    },
    [dataDesigner, handleDesignerRemove],
  );

  const designerOptionsRef = useRef(null);
  const handleSelectFiltered = useCallback(
    (val) => {
      setSelectDesignerFiltered(val);
      if (designerOptionsRef.current) {
        const opts = designerOptionsRef.current.getOptions();
        const { value, label } = getDesignerFiltered(designer, opts, val);

        setDesigner((p) => ({
          ...p,
          value,
          label,
        }));
      }
    },
    [designer],
  );

  const [selectAllStore, setSelectAllStore] = React.useState(false);
  const handleSelectAllStore = React.useCallback(
    (value) => {
      setSelectAllStore(value);
      let newStore = { value: [], label: [] };
      if (value) {
        const getKey = (stores, key) => {
          return (stores || [])
            .map((item) => (item[key] ? item[key] : null))
            .filter(Boolean);
        };

        const values = getKey(stores, "value");
        const labels = getKey(stores, "label");
        newStore = {
          ...newStore,
          value: values,
          label: labels,
        };
      }
      setStore((p) => ({ ...p, ...newStore }));
    },
    [stores],
  );

  const handleStoreLoadMore = () => {
    fetchMore({
      variables: {
        filter: {
          limit: 20,
          offset: stores.length,
          search: store.search,
        },
      },
      updateQuery: (prev, { fetchMoreResult, variables }) => {
        return {
          ...prev,
          stores: {
            ...prev.stores,
            nodes: [...prev.stores.nodes, ...fetchMoreResult.stores.nodes],
          },
        };
      },
    });
  };

  const filterClear = [
    handleDesignerRemove,
    handleTaskAssigneeRemove,
    handleFilterTimeRemove,
    handleOrderByRemove,
    handleOrderRemove,
    handleQueryRemove,
    handleStatusRemove,
    handleOrderSourceRemove,
    handleProductBaseRemove,
    handleStoreRemove,
    handleOrderTypeRemove,
    handleHasDesign,
    handleUsingDesignAppRemove,
    handleKpiRemove,
    handleBulkAcceptDesignRemove,
    handleUsingESRemove,
    handleCollectionRemove,
    handleTagRemove,
    handleNicheRemove,
    handleAuthorRemove,
    handleProduceCreatedViaRemove,
    handleHasPBArtworkRemove,
    handleDesignOnlineTeamRemove,
  ];

  const handleFiltersClearAll = useCallback(() => {
    for (let fn of filterClear) {
      fn && fn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...filterClear]);

  const filters = [
    ...(isIdeaTask
      ? [
          {
            key: "author",
            label: "Author",
            filter: (
              <FilterNoSearchPolaris
                data={authors}
                value={author}
                loading={loadingAuthor}
                error={errorAuthor}
                onChange={handleAuthorChange}
                onChangeSearch={handleAuthorSearch}
              />
            ),
          },
        ]
      : []),
    {
      key: "designer",
      label: "Designer",
      filter: (
        <div>
          <FilterNoSearchMVPolaris
            data={dataDesigner}
            value={designer}
            loading={loadingD}
            error={errorD}
            limit={1000}
            onChange={handleDesignerChange}
            ref={designerOptionsRef}
            onChangeSearch={({ search }) =>
              setDesigner((prev) => ({ ...prev, search }))
            }
          />
          <div
            style={{
              marginTop: "1rem",
              borderTop: "1px solid #dfe3e8",
              paddingTop: ".5rem",
            }}
          >
            <Stack distribution="equalSpacing">
              <Checkbox
                label="Select All"
                onChange={handleSelectAllDesigner}
                checked={selectAllDesigner}
              />
              <Checkbox
                label="Select Designer Filtered"
                onChange={handleSelectFiltered}
                checked={selectDesignerFiltered}
              />
            </Stack>
          </div>
        </div>
      ),
    },
    !isDesignOnline && {
      label: "Design Online Team",
      key: "designOnlineTeam",
      filter: (
        <FilterHasSearchPolaris
          options={designOnlineTeams}
          value={designOnlineTeam}
          loading={loadingDOT}
          error={errorDOT}
          onChangeSearch={(search) => {
            setDesignOnlineTeam((p) => ({ ...p, search: search }));
          }}
          onChange={({ value, label }) =>
            setDesignOnlineTeam((p) => ({ ...p, value, label }))
          }
        />
      ),
    },
    showSimilarTaskAssignees
      ? {
          key: "taskAssignee",
          label: "Similar Task For Assignee",
          filter: (
            <FilterNoSearchPolaris
              data={dataDesigner2}
              value={taskAssignee}
              loading={loadingD2}
              error={errorD2}
              onChange={({ value, label }) =>
                setTaskAssignee((prev) => ({
                  ...prev,
                  value,
                  label,
                }))
              }
              onChangeSearch={({ search }) =>
                setTaskAssignee((prev) => ({ ...prev, search }))
              }
            />
          ),
        }
      : null,
    ...(hasStoreFilter && !isIdeaTask
      ? [
          {
            key: "store",
            label: "Stores",
            filter: (
              <FilterHasSearchMVPolaris
                options={stores}
                value={store}
                loading={networkStatus === 1 && loadingStore}
                error={errorStore}
                onChangeSearch={(search) =>
                  setStore((prevState) => ({
                    ...prevState,
                    search,
                  }))
                }
                onChange={({ value, label }) => {
                  setStore((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }));
                }}
                prevOptionMarkup={
                  <div style={{ marginTop: "1rem" }}>
                    <Checkbox
                      label="Select All"
                      checked={selectAllStore}
                      onChange={handleSelectAllStore}
                    />
                  </div>
                }
                nextOptionMarkup={
                  <div style={{ marginTop: "1rem" }}>
                    <Button
                      plain
                      children="Load more"
                      onClick={handleStoreLoadMore}
                      size="slim"
                      loading={networkStatus === 3 && loadingStore}
                    />
                  </div>
                }
              />
            ),
          },
        ]
      : []),
    ...(!isDesigner && !isIdeaTask
      ? [
          {
            key: "productBase",
            label: "Product base",
            filter: (
              <FilterNoSearchMVPolaris
                data={productBases}
                value={productBase}
                onChangeSearch={({ search }) =>
                  setProductBase((prev) => ({
                    ...prev,
                    search,
                  }))
                }
                onChange={({ value, label }) => {
                  setProductBase((prev) => ({
                    ...prev,
                    value,
                    label,
                  }));
                }}
              />
            ),
          },
        ]
      : []),
    ...(isIdeaTask || !isDesigner
      ? [
          {
            key: "status",
            label: "Status",
            filter: (
              <FilterListRadioPolaris
                value={status && status.value}
                options={NEW_STATUS_OPTIONS}
                onChange={({ value, label }) =>
                  setStatus((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
        ]
      : []),
    {
      key: "filterTime",
      label: "Filter Time By",
      filter: (
        <FilterTimeByPolaris
          isLeader={isLeader}
          isIdeaTask={isIdeaTask}
          filterTime={filterTime}
          onChange={(field, fieldLabel) => {
            setFilterTime((filterTime) => ({
              ...filterTime,
              field,
              fieldLabel,
            }));
          }}
          onChangeRange={(range, rangeLabel) => {
            setFilterTime((filterTime) => ({
              ...filterTime,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
    {
      key: "order",
      label: "Order",
      filter: (
        <FilterSortOrderPolaris
          order={order}
          onChange={(v) => {
            setOrder(v);
          }}
        />
      ),
    },
    {
      key: "orderBy",
      label: "Order By",
      filter: (
        <FilterOrderByPolaris
          orderBy={orderBy.value}
          options={ORDER_BY_OPTIONS2}
          onChange={(value, label) => {
            setOrderBy({
              value: value,
              label: label,
            });
          }}
        />
      ),
    },
    ...(isLeader && !isIdeaTask
      ? [
          {
            key: "order-type",
            label: "Order Type",
            filter: (
              <FilterListRadioPolaris
                value={orderType.value}
                options={ORDER_TYPE}
                onChange={({ value, label }) =>
                  setOrderType((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
          {
            key: "hasDesign",
            label: "Has Design",
            filter: (
              <FilterListRadioPolaris
                value={hasDesign.value}
                options={HAS_DESIGN}
                onChange={({ value, label }) =>
                  setHasDesign((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
          {
            key: "hasPBArtwork",
            label: "Has PB Artwork",
            filter: (
              <FilterListRadioPolaris
                value={hasPBArtwork.value}
                options={APP_DESIGN_ACTION}
                onChange={({ value, label }) =>
                  setHasPBArtwork((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
        ]
      : []),
    // {
    //   key: "platform",
    //   label: "Platform",
    //   filter: (
    //     <FilterListRadioPolaris
    //       value={orderSource && orderSource.value}
    //       options={ORDER_SOURCE_OPTIONS}
    //       onChange={({ value, label }) =>
    //         setOrderSource((prevState) => ({ ...prevState, value, label }))
    //       }
    //     />
    //   ),
    // },
    ...(!isIdeaTask
      ? [
          {
            key: "platform",
            label: "Platform",
            filter: (
              <FilterNoSearchMVPolaris
                noSearch
                value={orderSource}
                data={ORDER_SOURCE_OPTIONS}
                onChange={({ value, label }) =>
                  setOrderSource((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
          {
            key: "kpi",
            label: "KPI",
            filter: (
              // <FilterListRadioPolaris
              //   value={kpi.value}
              //   options={KPI_OPTIONS}
              //   onChange={({ value, label }) =>
              //     setKpi((prevState) => ({
              //       ...prevState,
              //       value,
              //       label,
              //     }))
              //   }
              // />
              <KPIComponent
                value={kpi.value}
                onChange={handleKpiChange}
                onChangeRange={handleRangeKpiChange}
                rangeValue={rangeKpi.value}
              />
            ),
          },
          {
            key: "bulk-ad",
            label: "Bulk Accept Design",
            filter: (
              <FilterListRadioPolaris
                value={bulkAcceptDesign.value}
                options={BULK_ACCEPT}
                onChange={({ value, label }) =>
                  setBulkAcceptDesign((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
          {
            key: "using-ad",
            label: "Using App Design",
            filter: (
              <FilterListRadioPolaris
                value={usingDesignApp.value}
                options={APP_DESIGN_ACTION}
                onChange={({ value, label }) =>
                  setUsingDesignApp((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
          {
            key: "express-shipping",
            label: "Using Express Shipping",
            filter: (
              <FilterListRadioPolaris
                value={usingES.value}
                options={USING_EXPRESS_SHIPPING}
                onChange={({ value, label }) =>
                  setUsingES((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
          {
            key: "productCreatedVia",
            label: "Product Created Via",
            filter: (
              <FilterListRadioPolaris
                value={productCreatedVia.value}
                options={PRODUCT_CREATED_VIA_OPTIONS}
                onChange={({ value, label }) =>
                  setProductCreatedVia((prevState) => ({
                    ...prevState,
                    value,
                    label,
                  }))
                }
              />
            ),
          },
        ]
      : [
          {
            key: "collection",
            label: "Collections",
            filter: (
              <FilterHasSearchMVPolaris
                value={collection}
                options={collections}
                loading={loadingCollection}
                error={errorCollection}
                onChange={handleCollectionChange}
                onChangeSearch={handleCollectionSearch}
              />
            ),
          },
          {
            key: "tag",
            label: "Tags",
            filter: (
              <FilterHasSearchMVPolaris
                value={tag}
                options={tags}
                loading={loadingTag}
                error={errorTag}
                onChange={handleTagChange}
                onChangeSearch={handleTagSearch}
              />
            ),
          },
          {
            key: "niche",
            label: "Niches",
            filter: (
              <FilterHasSearchMVPolaris
                value={niche}
                options={niches}
                loading={loadingNiche}
                error={errorNiche}
                onChange={handleNicheChange}
                onChangeSearch={handleNicheSearch}
              />
            ),
          },
        ]),
  ].filter(Boolean);

  const designerFilter = [
    {
      key: "filterTime",
      label: "Filter Time By",
      filter: (
        <FilterTimeByPolaris
          filterTime={filterTime}
          onChange={(field, fieldLabel) => {
            setFilterTime((filterTime) => ({
              ...filterTime,
              field,
              fieldLabel,
            }));
          }}
          onChangeRange={(range, rangeLabel) => {
            setFilterTime((filterTime) => ({
              ...filterTime,
              range,
              rangeLabel,
            }));
          }}
        />
      ),
    },
    {
      key: "order",
      label: "Order",
      filter: (
        <FilterSortOrderPolaris
          order={order}
          onChange={(v) => {
            setOrder(v);
          }}
        />
      ),
    },
    {
      key: "kpi",
      label: "KPI",
      filter: (
        <FilterListRadioPolaris
          value={kpi.value}
          options={KPI_OPTIONS}
          onChange={({ value, label }) =>
            setKpi((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
        />
      ),
    },
    {
      key: "using-ad",
      label: "Using App Design",
      filter: (
        <FilterListRadioPolaris
          value={usingDesignApp.value}
          options={APP_DESIGN_ACTION}
          onChange={({ value, label }) =>
            setUsingDesignApp((prevState) => ({
              ...prevState,
              value,
              label,
            }))
          }
        />
      ),
    },
  ].filter(Boolean);

  const appliedFilters = [];
  if (!isEmpty(designer.label)) {
    const key = "designer";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, designer.label),
      onRemove: handleDesignerRemove,
    });
  }
  if (!isEmpty(taskAssignee.label)) {
    const key = "taskAssignee";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taskAssignee.label),
      onRemove: handleTaskAssigneeRemove,
    });
  }
  if (!isEmpty(store.label)) {
    const key = "store";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, store.label),
      onRemove: handleStoreRemove,
    });
  }
  if (productBase.label.length) {
    const key = "productBase";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productBase.label),
      onRemove: handleProductBaseRemove,
    });
  }
  if (!isEmpty(status.label) || typeof hasAssign === "boolean") {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status.label),
      onRemove: handleStatusRemove,
    });
  }
  if (
    (!isEmpty(filterTime.fieldLabel) &&
      "tasks.created_ad" !== filterTime.field) ||
    (!isEmpty(filterTime.rangeLabel) && filterTime.range)
  ) {
    const key = "filterTime";
    let { fieldLabel, rangeLabel, range, field } = filterTime;
    let value = "";
    if (
      null !== fieldLabel &&
      null !== rangeLabel &&
      "Task Created Date" !== fieldLabel
    ) {
      value = `${fieldLabel} & ${rangeLabel}`;
    } else if (
      null !== field &&
      null !== fieldLabel &&
      "Task Created Date" !== fieldLabel
    ) {
      value = `${fieldLabel}`;
    } else if (null !== range) {
      value = `${rangeLabel}`;
    }
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value),
      onRemove: handleFilterTimeRemove,
    });
  }
  if (!isEmpty(orderBy.label) && orderBy.value) {
    const key = "orderBy";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, orderBy.label),
      onRemove: handleOrderByRemove,
    });
  }
  if (!isEmpty(order)) {
    const key = "order";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, order),
      onRemove: handleOrderRemove,
    });
  }
  if (orderSource.label.length) {
    const key = "platform";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, orderSource.label),
      onRemove: handleOrderSourceRemove,
    });
  }
  if (!isEmpty(orderType.label)) {
    const key = "order-type";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, orderType.label),
      onRemove: handleOrderTypeRemove,
    });
  }
  if (!isEmpty(kpi.label) || !isEmpty(rangeKpi.label)) {
    const key = "kpi";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, kpi.label || rangeKpi.label),
      onRemove: handleKpiRemove,
    });
  }

  if (!isEmpty(hasDesign.label)) {
    const key = "hasDesign";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, hasDesign.label),
      onRemove: handleHasDesign,
    });
  }
  if (!isEmpty(usingDesignApp.label)) {
    const key = "usingDesignApp";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, usingDesignApp.label),
      onRemove: handleUsingDesignAppRemove,
    });
  }
  if (!isEmpty(hasPBArtwork.label)) {
    const key = "hasPBArtwork";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, hasPBArtwork.label),
      onRemove: handleHasPBArtworkRemove,
    });
  }
  if (!isEmpty(bulkAcceptDesign.label)) {
    const key = "bulk-ad";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, bulkAcceptDesign.label),
      onRemove: handleBulkAcceptDesignRemove,
    });
  }
  if (!isEmpty(productCreatedVia.label)) {
    const key = "productCreatedVia";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productCreatedVia.label),
      onRemove: handleProduceCreatedViaRemove,
    });
  }
  if (!isEmpty(usingES.label)) {
    const key = "express-shipping";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, usingES.label),
      onRemove: handleUsingESRemove,
    });
  }

  // ===================== ACTION REMOVE =====================
  if (!isEmpty(collection.label)) {
    const key = "collection";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, collection.label),
      onRemove: handleCollectionRemove,
    });
  }
  if (!isEmpty(tag.label)) {
    const key = "tag";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, tag.label),
      onRemove: handleTagRemove,
    });
  }
  if (!isEmpty(niche.label)) {
    const key = "niche";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, niche.label),
      onRemove: handleNicheRemove,
    });
  }
  if (!isEmpty(author.label)) {
    const key = "author";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, author.label),
      onRemove: handleAuthorRemove,
    });
  }

  if (!isEmpty(designOnlineTeam.label)) {
    const key = "designOnlineTeam";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, designOnlineTeam.label),
      onRemove: handleDesignOnlineTeamRemove,
    });
  }

  return (
    <Card sectioned>
      <Filters
        queryValue={queryValue}
        queryPlaceholder={`Filter tasks`}
        filters={isDesigner && !isIdeaTask ? designerFilter : filters}
        appliedFilters={appliedFilters}
        onQueryChange={handleFilterQueryChange}
        onQueryClear={handleQueryRemove}
        onClearAll={handleFiltersClearAll}
      />
    </Card>
  );
  function disambiguateLabel(key, value) {
    switch (key) {
      case "designer":
        let label =
          Array.isArray(value) && value.length > 0 ? value.join(", ") : "";
        return `Designer: ${label}`;
      case "taskAssignee":
        return `Similar Task Assignee: ${value}`;
      case "store":
        if ("string" !== typeof value) {
          if (arrInvalid(value)) {
            value = getStoreTitle(value);
          } else {
            const title = value
              .reduce((acc, cur) => {
                let txt = getStoreTitle(cur);
                acc.push(txt);
                return acc;
              }, [])
              .join(", ");

            value = title;
          }
        }

        return `Store: ${value}`;
      case "productBase":
        let pBaseLabel =
          Array.isArray(value) && value.length > 0 ? value.join(", ") : "";
        return `Product bases: ${pBaseLabel}`;
      case "status":
        let newValue = value;
        if (typeof hasAssign === "boolean") {
          if (hasAssign) {
            let suffix = "Assigned";
            newValue = value ? `${value} (${suffix})` : suffix;
          } else {
            let suffix = "Un-assigned";
            newValue = value ? `${value} (${suffix})` : suffix;
          }
        }
        if (isDesigner) {
          if (newValue === Pending) {
            newValue = AssignToMe;
          }
        }
        return `Status: ${newValue}`;
      case "filterTime":
        return `Time: ${value}`;
      case "orderBy":
        return `Order By: ${value}`;
      case "order":
        return `Order: ${value}`;
      case "platform":
        let platformLabel =
          Array.isArray(value) && value.length > 0 ? value.join(", ") : "";
        return `Platform: ${platformLabel}`;
      case "order-type":
        return `Order type: ${value}`;
      case "hasDesign":
        return `Has design: ${value}`;
      case "usingDesignApp":
        return `Using App Design: ${value}`;
      case "hasPBArtwork":
        return `Has PB Artwork: ${value}`;
      case "kpi":
        return `KPI: ${value}`;
      case "bulk-ad":
        return `Bulk Accept Design: ${value}`;
      case "express-shipping":
        return `Using Express Shipping: ${value}`;

      // Idea task
      case "collection":
        let collectionLabel = genLabelTree(value);
        return `Collections: ${collectionLabel}`;
      case "tag":
        let tagLabel = genLabelTree(value);
        return `Tags: ${tagLabel}`;
      case "niche":
        let nicheLabel = genLabelTree(value);
        return `Niches: ${nicheLabel}`;
      case "author":
        return `Author: ${value}`;
      case "productCreatedVia":
        return `Product Created Via: ${value}`;
      case "designOnlineTeam":
        return `Design Online Team: ${value}`;
      default:
        return value;
    }
  }
};

const getStoreTitle = (item) => {
  let res = "";

  let children = get(item, "props.children", null);
  if (Array.isArray(children)) {
    let currentLabel = children.find((i) => {
      return i?.props?.className === "title";
    });
    res = currentLabel?.props?.children;
  } else {
    res = children?.props.children;
  }

  return res;
};

function genTaxonomy(arr) {
  if (!arr || arr.length === 0) return null;
  return arr;
}

function KPIComponent({ value, onChange, onChangeRange, rangeValue }) {
  const [type, setType] = React.useState(rangeValue ? ["range"] : ["fixed"]);
  const [range, setRange] = React.useState({ from: "", to: "" });

  const timeoutRef = React.useRef(null);
  const handleRangeChange = React.useCallback((value, id) => {
    let values = {};
    setRange((p) => {
      values = { ...p, [id]: value };
      return values;
    });
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      onChangeRange(values);
    }, 500);
  }, []);

  React.useEffect(() => {
    if (rangeValue) {
      setType(["range"]);
    }
  }, [rangeValue]);

  const handleTypeChange = React.useCallback((selected) => {
    setType(selected);
    setRange({ from: "", to: "" });
  }, []);

  return (
    <KPIWrapper>
      <ChoiceList
        title="Type"
        selected={type}
        onChange={handleTypeChange}
        choices={[
          { value: "fixed", label: "Fixed" },
          { value: "range", label: "Range" },
        ]}
      />
      <div className="content">
        <label>Value: </label>
        <Collapsible
          open={type.includes("fixed")}
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <FilterListRadioPolaris
            value={value}
            options={KPI_OPTIONS}
            onChange={onChange}
          />
        </Collapsible>
        <Collapsible
          open={!type.includes("fixed")}
          transition={{ duration: "150ms", timingFunction: "ease" }}
        >
          <div className="range-wrap">
            <TextField
              id="from"
              onChange={handleRangeChange}
              placeholder="Enter from"
              label="From"
              value={range.from}
              type="number"
              min={0}
              max={10}
            />
            <TextField
              id="to"
              onChange={handleRangeChange}
              placeholder="Enter to"
              label="To"
              value={range.to}
              type="number"
              min={0}
              max={10}
            />
          </div>
        </Collapsible>
      </div>
    </KPIWrapper>
  );
}

function getAllDesigner(data) {
  const getValue = (key) => (item) => item[key];

  const ids = data.map(getValue("value"));
  const names = data.map(getValue("label"));
  const newVal = Array.from(new Set(ids).values());
  const newLabel = Array.from(new Set(names).values()).join(", ");

  return {
    value: newVal,
    label: newLabel,
  };
}

function getDesignerFiltered(selected, filtered, isPush) {
  const { value, label } = getAllDesigner(filtered);
  let ids = [];
  let labels = [];
  if (isPush) {
    ids = Array.from(new Set([...selected.value, ...value]).values());
    labels = Array.from(
      new Set([...selected.label, ...(label || "").split(/,\s*/)]).values(),
    );
  } else {
    ids = selected.value.filter((id) => !value.includes(id));
    labels = selected.value.filter((label) => !value.includes(label));
  }

  return { value: ids, label: labels };
}

const KPIWrapper = styled.div`
  > .Polaris-ChoiceList {
    > .Polaris-ChoiceList__Choices {
      display: flex;
      column-gap: 1rem;
    }
  }

  .content {
    padding-top: 5px;
  }

  .range-wrap {
    display: flex;
    column-gap: 10px;

    > * {
      flex: 1;
    }
  }
`;
