import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Badge, Modal, Stack, TextField } from "@shopify/polaris";
import { capitalize, get, head, includes } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { STATUS_DESIGN_TASK } from "../../../constants/task";
import { UPDATE_RESOURCE_TASK } from "../../../graphql/mutations";
import {
  GET_ORDER_DESIGNS,
  GET_TASKS_V2,
  GET_TASK_BY_ID,
  GET_TASK_SUBMISSION,
  GET_VALUE_FOR_ORDER,
} from "../../../graphql/queries";
import {
  getDesignPositionName,
  sortDesignPosition,
  toSlug,
  urlify,
} from "../../../helper";
import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import { ImageTypes, META_DATA } from "../../../variable";
import ModalImageClaimPolaris from "../../seller/ModalImageClaimPolaris";
import { CustomLinkPolaris } from "../../shared/CustomLinkPolaris";
import { formatFiles } from "../utils";
import { CommentsPolaris } from "./CommentsPolaris";
import { TabDetailPolaris } from "./TabDetailPolaris";

const ViewFirstFile = React.lazy(() =>
  import("../artist/design/ViewFirstFile"),
);

const LEFT_OPTIONS = [
  "Order ID",
  "Product title",
  "Product base",
  "Avaiable design position",
];
const NORMAL_OPTIONS = [
  "Order ID",
  "KPI",
  "Product title",
  "Requirements",
  "Product base",
];

export const ViewDetailPolaris = (props) => {
  const {
    task,
    open,
    toggleShowModal,
    toggleActive,
    onNotificationChange,
    filter,
  } = props;
  const taskStatus = task && task.status;
  // Block body scroll
  useLockBodyScroll(open);

  // State
  const [resourceChange, setResourceChange] = useState(false);
  const [items, setItems] = useState([]);
  const [inputResources, setInputResources] = useState(null);
  const baseIdRef = useRef(null);

  // Queries
  const [getTask, { data: dataT }] = useLazyQuery(GET_TASK_BY_ID);
  const [getFieldOrder, { data: dataO }] = useLazyQuery(GET_VALUE_FOR_ORDER);
  const [getOrderDesigns, { data: dataOD }] = useLazyQuery(GET_ORDER_DESIGNS);
  const [getTaskSubmission, { data: dataTS }] =
    useLazyQuery(GET_TASK_SUBMISSION);

  // Mutation
  const [updateResourceTask, { loading: loadingU, client }] = useMutation(
    UPDATE_RESOURCE_TASK,
    {
      onError: () => {},
      onCompleted: (res) => {
        setResourceChange(false);
        if (onNotificationChange) {
          onNotificationChange({
            message: "Update resource success.",
            isError: false,
          });
        }
        let newDescription = get(res, "updateResourceTask.description", null);
        const cache = client.readQuery({
          query: GET_TASKS_V2,
          variables: {
            filter,
          },
        });
        client.writeQuery({
          query: GET_TASKS_V2,
          variables: {
            filter,
          },
          data: {
            ...cache,
            tasksV2: {
              ...cache.tasksV2,
              nodes: cache.tasksV2.nodes.map((node) => {
                if (node.id === task.id) {
                  node = {
                    ...node,
                    description: newDescription,
                  };
                }
                return node;
              }),
            },
          },
        });
      },
    },
  );

  // Get data
  const filterStatus = get(filter, "status", null);
  useEffect(() => {
    if (task && open) {
      if (task.id) {
        // query Task
        getTask({
          variables: {
            id: task.id,
          },
        });

        // query Task submission
        const canGetTaskSubmission = ["Rejected", "Need Review"].includes(
          filterStatus,
        );
        if (canGetTaskSubmission) {
          getTaskSubmission({
            variables: {
              taskId: task.id,
              status: filterStatus,
            },
          });
        }
      }
      if (task.description) {
        setInputResources(task.description);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, open, filterStatus]);

  useEffect(() => {
    const taskById = get(dataT, "taskById", null);
    const orderId = get(taskById, "orderId", null);
    const skipQuery = get(taskById, "personalized");

    const taskProductBases = get(taskById, "product.productBases");
    const taskProductBaseFirst = head(taskProductBases);
    const taskProductBaseFirstId = get(taskProductBaseFirst, "id", null);

    if (orderId && open) {
      // query filed value for Order
      getFieldOrder({
        variables: {
          orderId,
        },
        skip: !skipQuery,
      });

      // query Related design file
      getOrderDesigns({
        variables: {
          id: orderId,
          productBaseId: taskProductBaseFirstId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataT, open]);

  // Format data
  useEffect(() => {
    let taskById = get(dataT, "taskById", null);
    // Product base
    let productBase = "";
    let orderData = get(taskById, "order", null);
    let pBase = get(
      orderData,
      "productVariant.productBaseVariant.productBase",
      null,
    );
    const productBaseTitle = pBase?.title;
    const productBaseId = pBase?.id;
    if (baseIdRef.current == null) {
      baseIdRef.current = productBaseId;
    }
    if (productBaseTitle) {
      productBase = productBaseTitle;
    } else {
      let productData = get(taskById, "product", null);
      let productBases = get(productData, "productBases", []);
      if (Array.isArray(productBases) && productBases.length) {
        let productBaseTitle = get(head(productBases), "title", null);
        productBase = productBaseTitle;
      }
    }

    // Attributes
    let orderItemAttributes = get(
      taskById,
      "order.productVariant.productBaseVariant.attributes",
      [],
    );
    let attributeMarkup = genAttributes(orderItemAttributes);

    // Personalized info
    let fields = get(dataO, "getFieldValueForOrder", null);
    let fieldsMarkup = genFields(fields);

    // Design position
    let designPositions = get(taskById, "product.designPositions", null);
    let baseVariantId = get(
      taskById,
      "order.productVariant.productBaseVariant.productBase.id",
      null,
    );

    const designPositionMarkup = genDesignPosition(
      sortDesignPosition(designPositions),
      baseVariantId,
    );

    // Related designs
    let relatedDesignMarkup;
    let anotherDesigns = get(dataOD, "getOrderDesigns.anotherDesigns", null);
    let siblingDesigns = get(dataOD, "getOrderDesigns.siblingDesigns", null);
    let orderDesigns = get(dataOD, "getOrderDesigns.orderDesigns", null);
    let originProduct = get(
      dataOD,
      "getOrderDesigns.originProduct.designPositions",
      [],
    );

    relatedDesignMarkup = genRelatedDesign(
      orderDesigns,
      originProduct,
      anotherDesigns,
      siblingDesigns,
    );

    // Rejected reason
    let reason = get(dataTS, "getTaskSubmission.rejectReason", null);
    let showRejectedReason = [STATUS_DESIGN_TASK.Rejected].includes(taskStatus);

    // Configured by
    const configByUser = get(dataTS, "getTaskSubmission.configByUser", {});

    // Title
    let pTitle = get(taskById, "product.title", null);
    if (pTitle?.includes("(fake)")) {
      pTitle = pTitle.replaceAll("(fake)", "");
    }

    const kpi = get(taskById, "kpi", null);
    const mix = genKPI(kpi, configByUser);
    let newItems = {
      "Order ID": get(taskById, "orderId", null),
      KPI: mix,
      "Product title": <span title={pTitle}>{pTitle}</span>,
      Requirements: get(taskById, "requirement", null),
      "Product base": productBase,
      ...(orderItemAttributes.length > 0
        ? { Attributes: attributeMarkup }
        : {}),
      "Personalized info": fieldsMarkup,
      "Avaiable design position": designPositionMarkup,
      "Related designs": relatedDesignMarkup,
      ...(showRejectedReason
        ? { "Rejected reason": <span>{reason}</span> }
        : {}),
    };

    let formatItems = [];
    for (let [key, value] of Object.entries(newItems)) {
      let item = {};
      if (key) {
        let isLeft = LEFT_OPTIONS.includes(key);
        let isNormal = NORMAL_OPTIONS.includes(key);

        item["term"] = key;
        item["description"] = value;
        item["className"] = toSlug(key);
        item["left"] = isLeft;
        item["normal"] = isNormal;
      }
      formatItems.push(item);
    }
    setItems(formatItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataT, dataO, dataOD, dataTS]);

  // Product thumb
  const taskById = get(dataT, "taskById", null);
  const product = get(taskById, "product", null);

  // Main image
  const pBaseId = baseIdRef.current;
  const mainImageId = product?.mainImageId;
  const images = product?.images;
  const isCampaign = product?.productBases?.length > 1;
  const haveMainImageId = mainImageId || mainImageId !== "undefined";

  const mainImages = React.useMemo(() => {
    if (!images || !Array.isArray(images) || images.length === 0) return [];
    return getMainImages(
      images,
      pBaseId,
      isCampaign,
      haveMainImageId,
      mainImageId,
    );
  }, [images, pBaseId, isCampaign, haveMainImageId, mainImageId]);

  const [fileFirst = null, fileUrl] = React.useMemo(() => {
    const [f] = mainImages || [];
    const u = f?.url || f?.thumbnailUrl || null;
    return [f, u];
  }, [mainImages]);

  // personalized Preview
  const personalizedPreview = get(taskById, "order.personalizedPreview", null);

  // psd Files
  const psdFiles = get(taskById, "product.psdFiles", []);

  // Design resources urls
  let psds = get(taskById, "product.psd", null);
  const productTitle = get(taskById, "product.title", "");
  const fake_pt = /\(fake\)/gi;
  if (fake_pt.test(productTitle) && !psds) {
    psds = get(taskById, "order.originalProduct.psd", null);
  }
  const psdPattern = /[\|\n]/g;
  if (psds) {
    psds = psds.split(psdPattern).filter(Boolean);
  }

  // Handle actions
  const handleSubmit = useCallback(() => {
    if (onNotificationChange) {
      onNotificationChange({
        message: null,
        isError: false,
      });
    }
    if (inputResources !== task.description) {
      updateResourceTask({
        variables: {
          taskId: task.id,
          description: inputResources,
        },
      });
      toggleActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputResources, task.description]);

  const handleResourceChange = useCallback((value) => {
    setInputResources(value);
    setResourceChange(true);
  }, []);

  const psdFilesMarkup =
    psdFiles?.length > 0 ? (
      <div>
        <h3>Design resource files:</h3>
        <PSDFContatiner>
          {psdFiles.map((psdf, idx) => {
            const href = psdf && psdf.url ? psdf.url : psdf.thumbnailUrl;
            const linkMarkup = (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginTop: "1rem",
                }}
              >
                <CustomLinkPolaris href={href} file={psdf}>
                  View origin
                </CustomLinkPolaris>
              </div>
            );
            const modalMarkup = (
              <ModalImageClaimPolaris
                files={{
                  files: [psdf],
                }}
                limit={1}
                width="120px"
                height="auto"
              />
            );
            return (
              <div className="psdf-wrap" key={`psdf-${idx}`}>
                {ImageTypes.includes(psdf.mimeType) ? (
                  <div
                    style={{
                      maxWidth: "120px",
                    }}
                  >
                    {modalMarkup}
                    {linkMarkup}
                  </div>
                ) : (
                  <div key={`psdf-${idx}`}>
                    <CustomLinkPolaris href={href}>
                      {psdf.name}
                    </CustomLinkPolaris>
                  </div>
                )}
              </div>
            );
          })}
        </PSDFContatiner>
      </div>
    ) : null;

  const psdContentMarkup =
    psds?.length > 0 ? (
      <div>
        <h3>Design resources urls:</h3>
        {psds.map((psd, idx) => (
          <div className="design-resources-urls" key={`psd-${idx}`}>
            {urlify(psd) ? (
              <CustomLinkPolaris href={psd}>{psd}</CustomLinkPolaris>
            ) : (
              <span>{psd}</span>
            )}
          </div>
        ))}
      </div>
    ) : null;

  const personalizedPreviewMarkup = personalizedPreview ? (
    <div className="personalized-preview">
      <h3>Personalized preview:</h3>
      <CustomLinkPolaris href={personalizedPreview}>
        <img
          alt=""
          src={personalizedPreview}
          style={{
            width: 160,
            height: 160,
            marginTop: "1rem",
            objectFit: "cover",
          }}
        />
      </CustomLinkPolaris>
    </div>
  ) : null;

  const resources = formatFiles(get(task, "resources")) || [];
  const [firstFile] = resources || [];
  const taskResourceMarkup = (
    <div className="resources_wrap">
      <Stack>
        <Stack.Item fill>
          <h3>Task resource: </h3>
          <TextField
            label="resources"
            labelHidden
            multiline={4}
            value={inputResources}
            onChange={handleResourceChange}
          />
        </Stack.Item>
        <Stack.Item fill>
          <h3>Task resource files: </h3>
          {resources?.length > 0 ? (
            <div className="resource-artist">
              <div>
                <ModalImageClaimPolaris
                  files={{
                    files: resources,
                  }}
                  limit={1}
                  width={160}
                  height="auto"
                />
              </div>
              <div className="file-name">
                <React.Suspense fallback={null}>
                  <ViewFirstFile file={firstFile} />
                </React.Suspense>
              </div>
            </div>
          ) : null}
        </Stack.Item>
      </Stack>
    </div>
  );

  return (
    <Modal
      title={`Task detail #${task?.id}`}
      open={open}
      onClose={toggleShowModal}
      sectioned
      large
      primaryAction={{
        content: resourceChange ? "Save" : "Done",
        onAction: resourceChange ? handleSubmit : toggleShowModal,
        loading: loadingU,
      }}
      secondaryActions={[{ content: "Cancel", onAction: toggleShowModal }]}
    >
      <Container>
        <div className="first-row">
          <div className="content_left">
            <div className="product-thumb_wrap">
              <h3>Product thumb: </h3>
              <ModalImageClaimPolaris
                files={{ files: mainImages }}
                limit={1}
                width="auto"
                height="auto"
                maxWidth="100%"
                maxHeight="300px"
                isCompare
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  margin: "0.5rem 0 1rem",
                }}
              >
                <CustomLinkPolaris href={fileUrl} file={fileFirst}>
                  View origin
                </CustomLinkPolaris>
              </div>
            </div>
            {personalizedPreviewMarkup}
            {psdFilesMarkup}
            {psdContentMarkup}
          </div>
          <div className="content_right">
            {/* <DescriptionList items={items} /> */}
            <CommentsPolaris taskId={task?.id} />
          </div>
        </div>
        <div>{taskResourceMarkup}</div>
        <div className="second-row">
          <TabDetailPolaris task={task} taskInfo={items} />
        </div>
      </Container>
    </Modal>
  );
};

// ============== Design position ==============
function genDesignPosition(data, baseVariantId) {
  if (!data || !Array.isArray(data) || data.length === 0) return null;

  const genItem = (item, index) => {
    const dp = item?.designPosition;
    const artworkGuide = dp?.artworkGuidelines;
    const baseId = dp?.productBaseId;
    const artworkGuideLength = artworkGuide?.length || 0;
    const matchBaseVariant = baseVariantId && [baseVariantId].includes(baseId);
    if (!matchBaseVariant) return null;

    const dpName = getDesignPositionName(dp);
    return (
      <div className="design-position_item" key={index}>
        {dp ? (
          <>
            <div className="design-position-title">
              {dpName ? <h4>{dpName}:</h4> : null}
              <span>{dp.description}</span>
            </div>
            {artworkGuideLength > 0 ? (
              <div className="design-position-artguide">
                <h4>Artguides: </h4>
                {artworkGuide.map((ag, idx) => {
                  return (
                    <FileMarkup
                      item={ag}
                      index={idx}
                      length={artworkGuideLength}
                      key={`design-position-${idx}`}
                    />
                  );
                })}
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    );
  };

  return data.map(genItem).filter(Boolean);
}

function FileMarkup({ item, index, length }) {
  const file = item?.file;
  const href = file?.url || file.thumbnailUrl;

  return (
    <div className="design-position-artguide_inner">
      <CustomLinkPolaris href={href}>
        {file?.name || ""}
        {index !== length - 1 ? " |" : ""}
      </CustomLinkPolaris>
    </div>
  );
}

// ============== Fields ==============
function genFields(fields) {
  if (!fields || !Array.isArray(fields) || fields.length === 0) return null;

  const genField = (f, index) => {
    const { value } = f || {};
    const valueLen = value?.length || 0;
    const genVal = (item, idx) => (
      <FieldMarkup key={`field-${idx}`} item={item} />
    );
    let markup = valueLen > 0 ? value.map(genVal) : null;

    let title = "";
    if (f.field) {
      title = f.field?.title || f.field?.name;
      title = title ? capitalize(title) : title;
    }

    return (
      <React.Fragment key={f.id}>
        <div className="personalized_item" key={f.id}>
          <div className="personalized_inner">
            <h4>{title}: </h4>
            {markup}
          </div>
        </div>
        {index !== fields.length - 1 ? "|" : ""}
      </React.Fragment>
    );
  };

  return fields.map(genField);
}

function FieldMarkup({ item }) {
  let hasImg = includes(item, META_DATA);
  if (hasImg) {
    return (
      <div className="personalized-img">
        <CustomLinkPolaris href={item}>
          <img src={item} alt="" />
        </CustomLinkPolaris>
      </div>
    );
  }
  return <span>{item}</span>;
}

// ============== Related Deisign ==============
function genRelatedDesign(
  orderDesigns,
  originProduct,
  anotherDesigns,
  siblingDesigns,
) {
  orderDesigns = filterFiles(orderDesigns);
  originProduct = filterFiles(originProduct);
  anotherDesigns = filterFiles(anotherDesigns);
  siblingDesigns = filterFiles(siblingDesigns);

  const designs = [
    {
      label: "Another design:",
      files: anotherDesigns,
    },
    {
      label: "Sibling design:",
      files: siblingDesigns,
    },
    {
      label: "Order design:",
      files: orderDesigns,
    },
    {
      label: "Origin product design:",
      files: originProduct,
    },
  ];

  return (
    <div className="related-design_wrap">
      {designs.map((ds, index) => (
        <DesignMarkup key={`design-${index}`} {...ds} />
      ))}
    </div>
  );
}

function DesignMarkup({ label, files }) {
  if (!files || !Array.isArray(files) || files.length === 0) return null;

  return (
    <div className="related-design">
      <h4>{label}</h4>
      <ModalImageClaimPolaris files={{ files }} width={120} height={120} />
    </div>
  );
}

// ============== Attributes ==============
function genAttributes(attrs) {
  if (!attrs || !Array.isArray(attrs) || attrs.length === 0) return null;

  return attrs.map((att, index) => (
    <React.Fragment key={index}>
      <div className="attribute-item" key={index}>
        <h4>{att.name}:</h4>
        <span>{att.option}</span>
      </div>
      {index !== attrs.length - 1 ? "|" : ""}
    </React.Fragment>
  ));
}

// ============== KPI ==============
function genKPI(kpi, user) {
  const { firstName, lastName } = user || {};
  const userConfigDesign = [firstName, lastName].filter(Boolean).join(" ");

  return (
    <div className="mix-wrap">
      <div className="kpi-wrap">
        <span>{kpi}</span>
      </div>
      {!!userConfigDesign ? (
        <div className="config-by-user">
          <strong>Configured by:</strong>
          <Badge status="success" children={userConfigDesign} />
        </div>
      ) : null}
    </div>
  );
}

// Utils
function filterFiles(data) {
  if (!data || !Array.isArray(data) || data.length === 0) return [];

  const filterFile = (item) => {
    if (!item || !item.file || !item.file.id) return null;
    if (item.file.url || item.file.thumbnailUrl) return item.file;
    return null;
  };

  return data.map(filterFile).filter(Boolean);
}

function getMainImages(
  images,
  pBaseId,
  isCampaign,
  haveMainImageId,
  mainImageId,
) {
  if (!images || !Array.isArray(images) || images.length === 0) return [];

  let res = [];
  const mapFile = (item) => item?.file;
  const filterFile = (item) => item?.file?.id === mainImageId;
  const filterByMainId = (images) => images.filter(filterFile).map(mapFile);

  if (pBaseId && isCampaign) {
    res = images.filter((img) => img?.productBaseId === pBaseId).map(mapFile);
    if (res.length > 1 && haveMainImageId) {
      res = filterByMainId(images);
    }
  } else if (haveMainImageId) {
    res = filterByMainId(images);
  }

  if (res.length === 0) {
    res = images.filter(Boolean).map(mapFile);
  }

  return res;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  justify-content: space-between;

  .resource-artist {
    margin-top: -1rem;
  }

  .resources_wrap {
    h3 {
      margin-bottom: 0.5rem;
    }
  }

  .first-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    row-gap: 2rem;
    .content_left {
      width: 60%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
    .content_right {
      width: 40%;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      label {
        font-weight: 600;
        margin-bottom: 0.4rem;
        display: inline-block;
      }
    }
    .related-design_wrap {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      .related-design {
        & + .related-design {
          border-top: 1px solid #d9d9d9;
          padding-top: 1rem;
        }
      }
    }
  }

  h3 {
    font-weight: 600;
    font-size: 1.1em;
  }

  .product-thumb_wrap {
    .modal-image-item {
      margin-left: -1rem;
      margin-right: -1rem;
      img {
        box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 15px -3px,
          rgba(0, 0, 0, 0.1) 0px 4px 6px 2px;
      }
    }
  }

  .Polaris-DescriptionList {
    border: 0.1rem solid var(--p-border-subdued, #dfe3e8);
    align-items: stretch;
    border-radius: 5px;
    .Polaris-DescriptionList__Term {
      padding: 1.6rem;
    }
    .Polaris-DescriptionList__Description {
      border-left: 0.1rem solid var(--p-border-subdued, #dfe3e8);
      align-self: stretch;
      padding: 1.6rem;
      margin-bottom: 0;
    }
  }
  .personalized-img {
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
  .design-resources-urls {
    &:not(:first-child) {
      margin-top: 0.785rem;
    }

    a {
      word-break: break-all;
      white-space: break-spaces;
    }
  }
  .design-position-artguide_inner {
    a {
      word-break: break-all;
    }
  }

  .content_wrap.kpi {
    align-items: center;
    .content_item {
      flex-grow: 1;
    }

    .mix-wrap {
      display: flex;
      flex-direction: rows;
      align-items: center;
      justify-content: space-between;

      .Polaris-Badge {
        font-weight: 600;
      }
    }
  }
`;

const PSDFContatiner = styled.div`
  .psdf-wrap + .psdf-wrap {
    border-top: 1px solid #f0f0f0;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
  }
`;
