import history from "./history";
import moment from "moment";
import {
  ACCOUNT_ATHIEN,
  ACCOUNT_THUONG,
  PME_TEAM_ID,
  TEAM_ROLE,
  TEAM_ROLE_PARAMS,
  USER_ROLE,
  USER_ROLE_PARAMS,
} from "./variable";
import get from "lodash/get";
import React from "react";
import { EXTRA_TASKS_KEYS, MAP_EXTRA_TASKS } from "./constants/task";
import { GET_VARIABLES } from "./graphql/queries";
import {
  MAPPING_EVENT_NAMES,
  PRIVILEGES_KEYS,
  SETTING_VARIABLES,
} from "./constants";

export const handleError = (err) => {
  if (err) {
    err = err.replace("Error: GraphQL error:", "");
  }
  return err;
};

export const isEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
export const validateUrl = (url) => {
  if (!url) {
    return false;
  }
  return /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
    url,
  );
};

export const toSlug = (text) => {
  if (!text) {
    return "";
  }
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

export const formatFileSize = (bytes, decimalPoint) => {
  if (bytes === 0) return "0 Bytes";
  let k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
export const decodeCSV = (strData, strDelimiter) => {
  strDelimiter = strDelimiter || ",";
  const objPattern = new RegExp(
    // Delimiters.
    "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
    "gi",
  );
  const arrData = [[]];
  let arrMatches = null;
  while ((arrMatches = objPattern.exec(strData))) {
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      arrData.push([]);
    }

    let strMatchedValue;
    if (arrMatches[2]) {
      strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    } else {
      strMatchedValue = arrMatches[3];
    }

    arrData[arrData.length - 1].push(strMatchedValue);
  }
  return arrData;
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea"); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const loadThumbnailImage = (file) => {
  if (new Date().getTime() - new Date(file.createdAt).getTime() > 120000) {
    return file.thumbnailUrl ? file.thumbnailUrl : file.url;
  }
  return file.url;
};

export const getProductMockupFolder = (sku) => {
  return `mockups/${sku}`;
};

export const getProductPrintFilesFolder = (sku) => {
  return `print_files/${sku}`;
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const handleDeepEqual = function deepEqual(x, y) {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === "object" && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
};

export function handleProductBaseFields(
  newFieldsValue,
  defaultFieldsValue,
  stateDefaultValue,
  propsValue,
) {
  // Page Add product base
  if (undefined === stateDefaultValue) {
    defaultFieldsValue.attributes = [];
    defaultFieldsValue.variants = [];
    if (newFieldsValue.hasVariant) {
      newFieldsValue.carrierPricing = undefined;
      newFieldsValue.supplierPricing = undefined;
    }

    let formatUndefined = ["categories", "attributes", "variants"];
    formatUndefined.forEach((i) => {
      if (undefined === newFieldsValue[i]) {
        newFieldsValue[i] = [];
      }
    });

    let formatEmptyPrice = ["regularPrice", "salePrice", "sellerPrice"];
    formatEmptyPrice.forEach((i) => {
      if ("" === newFieldsValue[i]) {
        newFieldsValue[i] = null;
      }
    });

    for (let i = 0; i < newFieldsValue.designPositions.length; i++) {
      if (null === newFieldsValue.designPositions[i].imageUrl) {
        delete newFieldsValue.designPositions[i].imageUrl;
      }
    }
    delete newFieldsValue.hasVariant;
    delete defaultFieldsValue.hasVariant;
  } else {
    // Page Edit product base
    let arr = ["status", "__typename", "id"];
    arr.forEach((i) => {
      delete defaultFieldsValue[i];
    });

    let ObjsCompare = [newFieldsValue, defaultFieldsValue];
    if (newFieldsValue.hasVariant) {
      newFieldsValue.carrierPricing = [];
      newFieldsValue.supplierPricing = [];
      if (undefined === newFieldsValue.suppliers) {
        newFieldsValue.suppliers = [];
      }
      if (undefined === newFieldsValue.carriers) {
        newFieldsValue.carriers = [];
      }
      newFieldsValue.designPositions.forEach((_, index) => {
        if (newFieldsValue.designPositions[index]) {
          delete newFieldsValue.designPositions[index].imageUrl;
        }
      });
    } else {
      newFieldsValue.attributes = null;
      newFieldsValue.variants = [];

      ObjsCompare.forEach((i) => {
        let fields = ["carrierPricing", "supplierPricing"];
        fields.forEach((f) => {
          if (i[f]) {
            i[f].forEach((_, index) => {
              if (null === i[f][index].price) {
                i[f][index].price = 0;
              }
              delete i[f][index].id;
              delete i[f][index].__typename;
            });
          }
        });
      });
    }

    let formatValues = ["carriers", "categories", "suppliers"];
    formatValues.forEach((i) => {
      if (0 < defaultFieldsValue[i].length) {
        let newValue = [];
        defaultFieldsValue[i].forEach((item) => {
          newValue.push(item.id);
        });
        defaultFieldsValue[i] = newValue;
      }
    });

    if ("string" === typeof newFieldsValue.categories) {
      newFieldsValue.categories = [newFieldsValue.categories];
    }

    if (0 < defaultFieldsValue.images.length) {
      defaultFieldsValue.images.forEach((_, index) => {
        delete defaultFieldsValue.images[index].__typename;
      });
    }
  }

  let formatEmptyStr = [
    "title",
    "slug",
    "baseCostDescription",
    "defaultContent",
    "defaultShortDescription",
    "details",
  ];
  formatEmptyStr.forEach((i) => {
    if (
      null !== newFieldsValue[i] &&
      0 === newFieldsValue[i].replace(/<(.|\n)*?>/g, "").trim().length
    ) {
      if (undefined === propsValue) {
        newFieldsValue[i] = null;
      } else {
        newFieldsValue[i] = "";
      }
    }
  });

  return {
    newFieldsValue,
    defaultFieldsValue,
  };
}

export function sortProductBaseVariantId(a, b) {
  if (a.productBaseVariantId < b.productBaseVariantId) {
    return -1;
  }
  if (a.productBaseVariantId > b.productBaseVariantId) {
    return 1;
  }
  return 0;
}

export const getQueryParams = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const setQueryParams = (name, value) => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  currentUrlParams.set(name, value);
  history.push(window.location.pathname + "?" + currentUrlParams.toString());
};

export const filterFromUrlQuery = () => {
  const p = getQueryParams("filter");
  let result = {};
  if (p) {
    try {
      result = JSON.parse(p);
    } catch (e) {
      console.log(e);
    }
  }
  return result;
};

export const convertObjectToParams = (obj) => {
  return Object.entries(obj)
    .map(([key, value]) => (key && value ? `${key}=${value}` : null))
    .filter(Boolean)
    .join("&");
};

export const convertStringToObject = (str) => {
  try {
    return str
      .replace("?", "")
      .split("&")
      .reduce((init, item) => {
        const [key, value] = item.split("=");
        return key && value
          ? {
              ...init,
              [key]: decodeURI(value),
            }
          : null;
      }, {});
  } catch (_) {
    return null;
  }
};

export function handleProductFields(newFieldsValue, defaultFieldsValue) {
  if (newFieldsValue?.variants?.length > 0) {
    newFieldsValue.variants = newFieldsValue.variants.sort(
      sortProductBaseVariantId,
    );
  }
  if (defaultFieldsValue?.variants?.length > 0) {
    defaultFieldsValue.variants = defaultFieldsValue.variants.sort(
      sortProductBaseVariantId,
    );
  }
  // let formatEmptyStr = ["description", "shortDescription"];
  let formatEmptyStr = ["description"];
  formatEmptyStr.forEach((i) => {
    if (newFieldsValue && newFieldsValue[i]) {
      if (0 === newFieldsValue[i].replace(/<(.|\n)*?>/g, "").trim().length) {
        newFieldsValue[i] = "";
      }
      newFieldsValue[i] = newFieldsValue[i].replace(/(\r\n|\n|\r)/gm, "");
    }

    if (defaultFieldsValue && defaultFieldsValue[i]) {
      defaultFieldsValue[i] = defaultFieldsValue[i].replace(
        /(\r\n|\n|\r)/gm,
        "",
      );
    }
  });

  if (newFieldsValue.pushToStores && !newFieldsValue.pushToStores.length) {
    newFieldsValue.pushToStores = null;
  }

  if (newFieldsValue.designPositions && newFieldsValue.designPositions.length) {
    newFieldsValue.designPositions.forEach((i) => {
      if (i) {
        delete i.fileId;
      }
    });
  }

  return {
    newFieldsValue,
    defaultFieldsValue,
  };
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export function handleCampaignFields(
  newFieldsValue,
  defaultFieldsValue,
  productBases,
) {
  if (undefined === newFieldsValue.title) {
    newFieldsValue.title = "";
  }
  function sortProductBaseVariantId(a, b) {
    if (a.designPositionId < b.designPositionId) {
      return -1;
    }
    if (a.designPositionId > b.designPositionId) {
      return 1;
    }
    return 0;
  }

  if (productBases && productBases.length) {
    for (let i = 0; i < productBases.length; i++) {
      if (
        newFieldsValue[`designPositions__${i}`] &&
        newFieldsValue[`designPositions__${i}`].length
      ) {
        newFieldsValue[`designPositions__${i}`] = newFieldsValue[
          `designPositions__${i}`
        ].sort(sortProductBaseVariantId);
        newFieldsValue[`designPositions__${i}`].forEach((ii) => {
          if (null === ii.fileId) {
            delete ii.fileId;
          }
        });
      }
      if (
        defaultFieldsValue[`designPositions__${i}`] &&
        defaultFieldsValue[`designPositions__${i}`].length
      ) {
        defaultFieldsValue[`designPositions__${i}`] = defaultFieldsValue[
          `designPositions__${i}`
        ].sort(sortProductBaseVariantId);
      }
    }
  }

  // let formatEmptyStr = ["description", "shortDescription"];
  let formatEmptyStr = ["description"];
  formatEmptyStr.forEach((i) => {
    if (
      null !== newFieldsValue[i] &&
      0 === newFieldsValue[i].replace(/<(.|\n)*?>/g, "").trim().length
    ) {
      newFieldsValue[i] = null;
    }
  });

  return {
    newFieldsValue,
    defaultFieldsValue,
  };
}

export function appliedFilter({
  productBase,
  handleProductBaseRemove,
  store,
  handleStoreRemove,
  status,
  handleStatusMemberRemove,
  role,
  handleRoleMemberRemove,
  productType,
  handleProductTypeRemove,
  roleUserAccountAdmin,
  handleRoleUserAccountAdminRemove,
  statusUserAccountAdmin,
  handleStatusUserAccountAdminRemove,
  statusSellerAccountAdmin,
  handleStatusSellerAccountAdminRemove,
}) {
  const appliedFilters = [];
  if (!isEmpty(productBase)) {
    const key = "productBase";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productBase),
      onRemove: handleProductBaseRemove,
    });
  }

  if (!isEmpty(store)) {
    const key = "store";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, store),
      onRemove: handleStoreRemove,
    });
  }

  if (!isEmpty(role)) {
    const key = "role";
    if (role !== "All") {
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, role),
        onRemove: handleRoleMemberRemove,
      });
    }
  }

  if (!isEmpty(status)) {
    const key = "status";
    if (status !== "All") {
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, status),
        onRemove: handleStatusMemberRemove,
      });
    }
  }

  if (!isEmpty(productType)) {
    const key = "productType";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, productType),
      onRemove: handleProductTypeRemove,
    });
  }

  if (!isEmpty(roleUserAccountAdmin)) {
    if (roleUserAccountAdmin !== "All") {
      const key = "roleUserAccountAdmin";
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, roleUserAccountAdmin),
        onRemove: handleRoleUserAccountAdminRemove,
      });
    }
  }
  if (!isEmpty(statusUserAccountAdmin)) {
    if (statusUserAccountAdmin !== "All") {
      const key = "statusUserAccountAdmin";
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, statusUserAccountAdmin),
        onRemove: handleStatusUserAccountAdminRemove,
      });
    }
  }
  if (!isEmpty(statusSellerAccountAdmin)) {
    if (statusSellerAccountAdmin !== "All") {
      const key = "statusSellerAccountAdmin";
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, statusSellerAccountAdmin),
        onRemove: handleStatusSellerAccountAdminRemove,
      });
    }
  }

  return appliedFilters;
}

export function disambiguateLabel(key, value) {
  switch (key) {
    case "productBase":
      return `Product base: ${value}`;
    case "store":
      return `Store: ${value}`;
    case "collection":
      return `Collections: ${value}`;
    case "tag":
      return `Tags: ${value}`;
    case "status":
      return `Status: ${value}`;
    case "role":
      return `Role: ${value}`;
    case "productType":
      return `Product type: ${value}`;
    case "roleUserAccountAdmin":
      return `Role: ${value}`;
    case "statusUserAccountAdmin":
      return `Status: ${value}`;
    case "statusSellerAccountAdmin":
      return `Status: ${value}`;
    default:
      return value;
  }
}

export function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
}

export function strToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export const getUnique = (arr, property) => {
  const unique = arr
    .map((e) => e[property])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arr[e])
    .map((e) => arr[e]);
  return unique;
};

export const customPaginate = (
  totalItems,
  currentPage = 1,
  pageSize = 10,
  maxPages = 10,
) => {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage, endPage, hasPrevious, hasNext;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
      if (maxPages < totalPages) {
        hasPrevious = false;
        hasNext = true;
      }
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
      if (maxPages < totalPages) {
        hasPrevious = true;
        hasNext = false;
      }
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
      if (maxPages < totalPages) {
        hasPrevious = true;
        hasNext = true;
      }
    }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i,
  );

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
    hasPrevious,
    hasNext,
  };
};

export const NumberToFixed = (number, size) => {
  return Number.parseFloat(number).toFixed(size);
};

export function uniqueArray(input) {
  return input.filter((v, i, a) => a.indexOf(v) === i);
}

export function multiSearchAnd(text, arrayKeywords) {
  if (
    text.length > 0 &&
    Array.isArray(arrayKeywords) &&
    arrayKeywords.length > 0
  ) {
    text = text.toLowerCase();
    arrayKeywords = uniqueArray(arrayKeywords);

    let searchExp = new RegExp("(" + arrayKeywords.join(")|(") + ")", "gi");
    let matches = text.match(searchExp);
    if (Array.isArray(matches) && matches.length > 0) {
      matches = uniqueArray(matches);
      return matches.length === arrayKeywords.length ? true : false;
    }
  }
  return false;
}

export function getDomainFromUrl(url) {
  url = url.replace(/(https?:\/\/)?(www.)?/i, "");
  // Get sub domain.
  url = url.split(".");
  url = url.slice(0, 2).join("."); // url.length - 2
  if (url.indexOf("/") !== -1) {
    return url.split("/")[0];
  }
  return url;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const convertToPaged = (limit, offset) => {
  limit = Number(limit);
  offset = Number(offset);
  return offset / limit + 1;
};

export const convertToOffset = (limit, paged) => {
  limit = Number(limit);
  paged = Number(paged);

  if (!limit) {
    limit = 20;
  }
  if (!paged) {
    paged = 1;
  }

  return paged * limit - limit;
};

export const removeFieldWithoutFilter = (object, array) => {
  object = Object(object);
  let result = {};

  Object.keys(object).forEach((key) => {
    if (array.includes(key)) {
      result[key] = object[key];
    }
  });
  return result;
};

export const elementContains = (parent, child) =>
  parent !== child && parent.contains(child);

export const formatText = (str) => {
  // replace new line
  let newStr = str.replace(/(\r\n|\n|\r)/gm, "");
  // replace space.
  newStr = newStr.replace(/ +(?= )/g, "");
  return newStr;
};

export const formatTime = (time, pattern = "YYYY-MM-DD HH:mm:ss") => {
  if (time == null) return null;
  return moment(time).format(pattern);
};

export const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return urlRegex.test(text);
};

function rgbFromHueAndChroma(hue, chroma) {
  const huePrime = hue / 60;
  const hueDelta = 1 - Math.abs((huePrime % 2) - 1);
  const intermediateValue = chroma * hueDelta;

  let red = 0;
  let green = 0;
  let blue = 0;
  if (huePrime >= 0 && huePrime <= 1) {
    red = chroma;
    green = intermediateValue;
    blue = 0;
  }

  if (huePrime >= 1 && huePrime <= 2) {
    red = intermediateValue;
    green = chroma;
    blue = 0;
  }

  if (huePrime >= 2 && huePrime <= 3) {
    red = 0;
    green = chroma;
    blue = intermediateValue;
  }

  if (huePrime >= 3 && huePrime <= 4) {
    red = 0;
    green = intermediateValue;
    blue = chroma;
  }

  if (huePrime >= 4 && huePrime <= 5) {
    red = intermediateValue;
    green = 0;
    blue = chroma;
  }

  if (huePrime >= 5 && huePrime <= 6) {
    red = chroma;
    green = 0;
    blue = intermediateValue;
  }

  return { red, green, blue };
}

export function hsbToRgb(color) {
  const { hue, saturation, brightness, alpha = 1 } = color;
  const chroma = brightness * saturation;

  let { red, green, blue } = rgbFromHueAndChroma(hue, chroma);

  const chromaBrightnessDelta = brightness - chroma;
  red += chromaBrightnessDelta;
  green += chromaBrightnessDelta;
  blue += chromaBrightnessDelta;

  return {
    red: Math.round(red * 255),
    green: Math.round(green * 255),
    blue: Math.round(blue * 255),
    alpha,
  };
}

export function rgbToHsbl(color, type = "b") {
  const { alpha = 1 } = color;

  const red = color.red / 255;
  const green = color.green / 255;
  const blue = color.blue / 255;

  const largestComponent = Math.max(red, green, blue);
  const smallestComponent = Math.min(red, green, blue);

  const delta = largestComponent - smallestComponent;
  const lightness = (largestComponent + smallestComponent) / 2;
  let saturation = 0;
  if (largestComponent === 0) {
    saturation = 0;
  } else if (type === "b") {
    saturation = delta / largestComponent;
  } else if (type === "l") {
    const baseSaturation =
      lightness > 0.5
        ? delta / (2 - largestComponent - smallestComponent)
        : delta / (largestComponent + smallestComponent);
    saturation = isNaN(baseSaturation) ? 0 : baseSaturation;
  }

  let huePercentage = 0;
  switch (largestComponent) {
    case red:
      huePercentage = (green - blue) / delta + (green < blue ? 6 : 0);
      break;
    case green:
      huePercentage = (blue - red) / delta + 2;
      break;
    case blue:
      huePercentage = (red - green) / delta + 4;
  }

  const hue = (huePercentage / 6) * 360;
  const clampedHue = clamp(hue, 0, 360);

  return {
    hue: clampedHue ? roundNumberToDecimalPlaces(clampedHue, 2) : 0,
    saturation: roundNumberToDecimalPlaces(clamp(saturation, 0, 1), 4),
    brightness: roundNumberToDecimalPlaces(clamp(largestComponent, 0, 1), 4),
    lightness: roundNumberToDecimalPlaces(lightness, 4),
    alpha: roundNumberToDecimalPlaces(alpha, 4),
  };
}

export function roundNumberToDecimalPlaces(value, decimals) {
  const exponent = Number(`${value}e${decimals}`);
  const roundedExponent = Math.round(exponent);
  const numberWithDecimalPlaces = Number(`${roundedExponent}e-${decimals}`);
  return numberWithDecimalPlaces;
}

export function clamp(number, min, max) {
  if (number < min) return min;
  if (number > max) return max;
  return number;
}

export const generatorPassword = () => {
  return Array(12)
    .fill(
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*",
    )
    .map(function (x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join("");
};

const MB_API = "https://api.merchbridge.com/get_s3_url";
export function copyUrlImage(file) {
  const source = file?.url || file?.thumbnailUrl;

  const url = source ? new URL(source) : "";
  const newKey = url?.pathname;
  let key = file?.key || newKey;

  let name = newKey?.split(/\//g);
  name = name?.length > 0 ? name[name.length - 1] : "";

  if (!name) {
    name = generatorPassword() + ".jpg";
  }

  // add time
  let d = new Date();
  d = d.getTime();
  key = key + "|" + d;

  const str = [MB_API, window.btoa(key), name].join("/");
  copyToClipboard(str);
}

export function matchValue(value, lookup, ...args) {
  if (value in lookup) {
    const returnValue = lookup[value];
    return typeof returnValue === "function"
      ? returnValue(...args)
      : returnValue;
  }
  return undefined;
}

export function formatAggregations(agg, getValue) {
  if (!agg) return null;
  return Object.entries(agg).reduce((acc, [key, object]) => {
    const { doc_count, value } = object || {};
    const slug = key.replace(
      /_\w/g,
      (str) => str[1].toUpperCase() + str.slice(2),
    );

    let count = doc_count != null ? doc_count : value;
    if (typeof getValue === "boolean") {
      count = getValue ? value : doc_count;
    }

    if (count != null) {
      return {
        ...acc,
        [slug]: count,
      };
    }
    return acc;
  }, {});
}

export function checkRole(user) {
  if (!user || typeof user !== "object") return {};

  const { teamUser, roles } = user;
  const { role } = teamUser || {};

  const fn = (curRole) => {
    return curRole === role;
  };

  const getRolesSystem = (role) =>
    (roles || []).includes(role) && teamUser == null;

  let isTeamRoot = false;
  let teamRoot = get(user, "teamUser.team.isRoot", false);
  if (teamRoot != null) {
    isTeamRoot = teamRoot;
  } else {
    let teamRoot2 = get(user, "teams[0].isRoot", false);
    isTeamRoot = !!teamRoot2;
  }

  return {
    isAdministrator: getRolesSystem("Administrator"),
    isSeller: fn(TEAM_ROLE.Admin), // Team role `Admin` is Seller.
    isMarketplaceManager: fn(TEAM_ROLE.MarketplaceManager),
    isStoreManager: fn(TEAM_ROLE.StoreManager),
    isDesignLeader: fn(TEAM_ROLE.DesignLeader),
    isDesigner: fn(TEAM_ROLE.Designer),
    isIdeaLeader: fn(TEAM_ROLE.IdeaLeader),
    isIdea: fn(TEAM_ROLE.Idea),
    isSupplier: fn(TEAM_ROLE.Supplier),
    isSupporter: fn(TEAM_ROLE.Supporter),
    isAccountant: fn(TEAM_ROLE.Accountant),
    isSupplierSystem: getRolesSystem(USER_ROLE.Supplier),
    isTeamSeller: (roles || []).includes(USER_ROLE.Seller) && teamUser != null,
    isTeamRoot,
    isOnline: !!user?.isOnline,
    isArtist: fn(TEAM_ROLE.Artist),
    isArtistLeader: fn(TEAM_ROLE.ArtistLeader),
  };
}

export function canViewWholeTeamReport(user) {
  if (!user || typeof user !== "object") return false;

  const { privileges } = user;
  if (!privileges || !Array.isArray(privileges) || privileges.length === 0)
    return false;

  return privileges.includes(PRIVILEGES_KEYS.VWTR);
}

export function getParam(user) {
  if (!user) return "";
  if (user.teamUser == null) {
    const p = getParamByUserRole(user);
    return p;
  }

  const p = getParamByRole(user);
  return p;
}

export function getParamByRole(user) {
  if (!user || typeof user !== "object") return "";

  const { teamUser } = user;
  const { role } = teamUser || {};

  let param = TEAM_ROLE_PARAMS.Seller;
  switch (role) {
    case TEAM_ROLE.DesignLeader:
      param = TEAM_ROLE_PARAMS.DesignLeader;
      break;
    case TEAM_ROLE.MarketplaceManager:
      param = TEAM_ROLE_PARAMS.MarketplaceManager;
      break;
    case TEAM_ROLE.StoreManager:
      param = TEAM_ROLE_PARAMS.StoreManager;
      break;
    case TEAM_ROLE.IdeaLeader:
      param = TEAM_ROLE_PARAMS.IdeaLeader;
      break;
    case TEAM_ROLE.Idea:
      param = TEAM_ROLE_PARAMS.Idea;
      break;
    case TEAM_ROLE.Supporter:
      param = TEAM_ROLE_PARAMS.SellerSupporter;
      break;
    case TEAM_ROLE.Designer:
      param = TEAM_ROLE_PARAMS.Designer;
      break;
    case TEAM_ROLE.Supplier:
      param = TEAM_ROLE_PARAMS.Supplier;
      break;
    case TEAM_ROLE.Artist:
      param = TEAM_ROLE_PARAMS.Artist;
      break;
    case TEAM_ROLE.ArtistLeader:
      param = TEAM_ROLE_PARAMS.ArtistLeader;
      break;
    default:
      break;
  }

  return param;
}

const Administrator = "Administrator";
export function getParamByUserRole(user) {
  if (!user || !Object.prototype.hasOwnProperty.call(user, "roles")) return "";

  const { roles } = user;
  const [role] = roles || [];

  let param = "";
  switch (role) {
    case Administrator:
      param = USER_ROLE_PARAMS.Administrator;
      break;
    case USER_ROLE.Seller:
      param = USER_ROLE_PARAMS.Seller;
      break;
    case USER_ROLE.Supporter:
      param = USER_ROLE_PARAMS.Supporter;
      break;
    case USER_ROLE.Supplier:
      param = USER_ROLE_PARAMS.Supplier;
      break;
    default:
      break;
  }

  return param;
}

export function genStrToBtoA(str) {
  if (!str || !window) return;

  return window.btoa(unescape(encodeURIComponent(str)));
}

export function checkMobile() {
  let result = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
      navigator.userAgent.substr(0, 4),
    )
  ) {
    result = true;
  }

  return result;
}

export function calculatePercent(newValue, oldValue) {
  if (!checkTypeNumber(newValue) || !checkTypeNumber(oldValue))
    return undefined;

  const result = (oldValue / newValue) * 100;
  return result.toFixed(2);
}

export function checkTypeNumber(value) {
  return typeof value === "number" && Number.isFinite(value);
}

export function cloneByJSON(value) {
  if (typeof value === "undefined" || value == null) return null;

  return JSON.parse(JSON.stringify(value));
}

export function matchPathName(path) {
  const { location } = history || {};
  const { pathname } = location || {};
  return pathname === path;
}

export function getPathFromRouter(props) {
  const { match } = (props || [])[0];
  const { path } = match || {};
  return path;
}

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

let throttleTimer = false;
export const throttle = (callback, time = 250) => {
  if (throttleTimer) return;

  throttleTimer = true;
  setTimeout(() => {
    callback();
    throttleTimer = false;
  }, time);
};

export function isSlugColor(slug) {
  if (!slug || slug.length === 0) return false;
  const pt = /color(s)*/i;

  return pt.test(slug);
}

export function getPrivateFeatures(user) {
  if (!user) return [];
  const { teamUser, teams } = user;
  let { team } = teamUser || {};
  if (team == null) {
    team = get(teams, "[0]");
  }
  const { privateFeatures } = team || {};
  return privateFeatures || [];
}

function buildDataTree(data, level = 0) {
  if (!data || data.length === 0) return [];

  const res = [];
  for (let col of data) {
    const { children } = col;
    res.push(col);
    col.level = level;
    if (children?.length > 0) {
      res.push(...buildDataTree(children, level + 1));
    }
  }
  return res;
}

export function formatDataTree(data) {
  if (!data || data.length === 0) return [];
  let res = buildDataTree(data);
  return res.map(({ id, name, level }) => {
    const nameMarkup = level ? "&mdash;".repeat(level) + "&nbsp;" + name : name;
    return {
      value: id,
      label: <span dangerouslySetInnerHTML={{ __html: nameMarkup }}></span>,
    };
  });
}

export function genLabelTree(labels) {
  return Array.isArray(labels) && labels.length > 0
    ? labels
        .map((item) => {
          if (typeof item === "string") return item;

          let l = item?.props?.dangerouslySetInnerHTML?.__html || "";
          const pt = /\&mdash\;|\&nbsp\;/g;
          l = l.replace(pt, "");
          return l;
        })
        .join(", ")
    : "";
}

export function canUseDom() {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
}

function sortAttrName(arr = []) {
  return arr.sort((a, b) => {
    const al = a && a.toLowerCase();
    const bl = b && b.toLowerCase();
    if (al < bl) {
      return -1;
    }
    if (al > bl) {
      return 1;
    }
    return 0;
  });
}

export function getAttributeName(attributes) {
  if (!attributes || attributes.length === 0) return ";";

  const TYPE = "Type";
  let baseName = [];
  const remainAttributes = [];

  const newAttributes = [...attributes].filter(
    ({ name }) => name !== "_WCPA_order_meta_data",
  );

  for (let i = 0; i < newAttributes.length; i++) {
    const attr = newAttributes[i];
    if (attr?.name === TYPE) {
      baseName.push(attr);
    } else {
      remainAttributes.push(attr);
    }
  }

  baseName = baseName.map((a) => a?.option?.trim()).filter(Boolean);
  let result = remainAttributes
    .map((att) => att?.option?.trim())
    .filter(Boolean);

  result = sortAttrName(result);
  return [...baseName, ...result].join("-");
}

export function getTeamID(user) {
  if (!user || typeof user !== "object") return null;
  let teamId = get(user, "teams[0].id");
  if (!teamId) {
    teamId = get(user, "teamUser.team.id");
  }

  return teamId;
}

export function isRootTeam(user) {
  if (!user || typeof user !== "object") return false;
  let isRoot = get(user, "teams[0].isRoot");
  if (isRoot == null) {
    isRoot = get(user, "teamUser.team.isRoot");
  }

  return isRoot;
}

export function isPME_TEAM(user) {
  if (!user || typeof user !== "object") return false;
  let teamId = getTeamID(user);

  if (!teamId) return false;
  return teamId === PME_TEAM_ID || teamId === "IyqWIGOsAe" || teamId === "HsHQ";
}

export function isAccAThien(user) {
  const email = get(user, "email");

  return email === ACCOUNT_ATHIEN;
}

export function isAccThuong(user) {
  const email = get(user, "email");

  return email === ACCOUNT_THUONG;
}

export function getBaseTitle(base) {
  if (!base || typeof base !== "object") return "";

  const { title, teamId } = base;
  if (!teamId) {
    return `${title} ( Merch Bridge )`;
  }
  return title;
}

export function arrInvalid(arr) {
  return !arr || !Array.isArray(arr) || arr.length === 0;
}

export function objectInvalid(obj) {
  return !obj || typeof obj !== "object";
}

export const reducerFn = (prev, state) => {
  const newState = typeof state === "function" ? state(prev) : state;
  return { ...prev, ...newState };
};

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function checkRealDimension(designPosition, realWidth, realHeight) {
  let realDmOk = false;

  if (designPosition?.description?.length > 0 && realWidth && realHeight) {
    let originDesc = designPosition.description;

    realWidth = parseInt(realWidth);
    realHeight = parseInt(realHeight);

    if (originDesc) {
      let splitDesc = originDesc.split("|");
      if (Array.isArray(splitDesc) && splitDesc[0] && splitDesc[0].length > 0) {
        let dimensionDesc = splitDesc[0];

        // Case: no dimension
        const dimensionValid = /px|cm|in/i;
        if (!dimensionValid.test(dimensionDesc)) {
          return true;
        }

        dimensionDesc = dimensionDesc.replace(/,/g, "");
        dimensionDesc = dimensionDesc.replace(/,/g, "");
        dimensionDesc = dimensionDesc.replace(/px/g, "");
        dimensionDesc = dimensionDesc.replace(/cm/g, "");
        dimensionDesc = dimensionDesc.replace(/\s+/g, "");
        if (dimensionDesc.length > 0) {
          let dmDesc = dimensionDesc.split("x");
          if (Array.isArray(dmDesc) && 2 !== dmDesc.length) {
            dmDesc = dimensionDesc.split("×");
          }
          if (Array.isArray(dmDesc) && 2 === dmDesc.length) {
            let splitWidth = dmDesc && dmDesc[0] ? parseInt(dmDesc[0]) : 0;
            let splitHeight = dmDesc && dmDesc[1] ? parseInt(dmDesc[1]) : 0;
            if (
              splitWidth > 0 &&
              splitHeight > 0 &&
              splitWidth === realWidth &&
              splitHeight === realHeight
            ) {
              realDmOk = true;
            }
          }
        }
      }
    }
  }
  return realDmOk;
}

export function isURLDriveLink(url) {
  if (!url) return false;
  const pt = /drive.google.com/gi;
  return !!url.match(pt);
}

export function replaceSizeForDriveLink(url) {
  if (isURLDriveLink(url)) {
    return url.replace(/sz=w200/, "sz=w4000");
  }
  return url;
}

export function getDesignPositionName(designPosition) {
  let { name, displayName } = designPosition || {};
  name = name || "";
  displayName = displayName || "";
  let res = displayName;
  if (name) {
    const len = displayName.length;
    const suf = len ? ` (${name})` : name;
    const n = name ? suf : "";
    res = displayName + n;
  }

  return res;
}

export function sortDesignPosition(value) {
  if (!value || !Array.isArray(value) || value.length === 0) return [];

  value.sort((a, b) => a?.designPosition?.sorting - b?.designPosition?.sorting);
  const index = value.findIndex(
    (v) => v.designPosition.name.toLowerCase() === "master",
  );

  if (index > 0) {
    [value[0], value[index]] = [value[index], value[0]];
  }

  return value;
}

export function uniqueArr(arr) {
  return Array.from(new Set(arr).values());
}

export function mapPathByExtraMenu(extraMenus, prefix = "/design-leader") {
  const tasksMenu = (extraMenus || []).filter((i) =>
    EXTRA_TASKS_KEYS.includes(i),
  );

  let pathMatch = [];
  for (let item of tasksMenu) {
    pathMatch.push(...MAP_EXTRA_TASKS[item].map((i) => prefix + i));
  }
  pathMatch = uniqueArr(pathMatch);
  return { tasksMenu, pathMatch };
}

export function sortAttributeForMap(attrsValid, sep = "-") {
  if (!attrsValid || !Array.isArray(attrsValid) || attrsValid.length === 0)
    return [];

  const sortNames = sortAttrName(attrsValid.map((attr) => attr.name));

  const mapOpts = {};
  for (let attr of attrsValid) {
    mapOpts[attr.name] = attr.option;
  }

  const opts = [];
  for (let name of sortNames) {
    const opt = mapOpts[name];
    opts.push(opt);
  }

  return opts.join(sep);
}

export async function getBackgroundImage(teamID) {
  if (!teamID || teamID.length === 0) return;

  const { __apolloClient__: client } = window;
  if (!client) return;

  try {
    const { data } = await client.query({
      query: GET_VARIABLES,
      variables: {
        filter: { search: `${teamID}${SETTING_VARIABLES.BG}` },
      },
    });

    const variables = get(data, "variables");
    if (!Array.isArray(variables) || variables.length === 0) return;

    return variables[0] ? variables[0].value : undefined;
  } catch (e) {}
  return;
}

export const needGetTotalMapping = (dataEvent, teamID) => {
  let valid = false;

  // valid event name
  if (
    dataEvent?.mappingEvents?.event &&
    MAPPING_EVENT_NAMES.includes(dataEvent.mappingEvents.event)
  ) {
    // valid team id
    if (dataEvent.mappingEvents.mappingItem?.teamId === teamID) {
      valid = true;
    }
  }

  return valid;
};

export const removeEl = (el) => {
  if (el.remove && typeof el.remove === "function") return el.remove();
  if (
    el.parentNode &&
    el.parentNode.removeChild &&
    typeof el.parentNode.removeChild === "function"
  )
    return el.parentNode.removeChild(el);

  return false;
};

export const genPaginateAggregation = ({ total, limit, offset, onChange }) => {
  const totalPage = Math.ceil(total / limit);
  const page = offset / limit + 1;

  return {
    page,
    totalPage,
    offset,
    limit,
    onChange,
    total,
  };
};

export function getFullName(user) {
  return [user?.firstName, user?.lastName].filter(Boolean).join(" ");
}

export function getMainImages({ images, pBaseId, isCampaign, mainImageId }) {
  if (arrInvalid(images)) return [];

  const haveMainImageId = mainImageId || mainImageId !== "undefined";
  let res = [];
  const mapFile = (item) => item?.file;
  const filterFile = (item) => item?.file?.id === mainImageId;
  const filterByMainId = (images) => images.filter(filterFile).map(mapFile);

  if (pBaseId && isCampaign) {
    res = images.filter((img) => img?.productBaseId === pBaseId).map(mapFile);
    if (res.length > 1 && haveMainImageId) {
      res = filterByMainId(images);
    }
  } else if (haveMainImageId) {
    res = filterByMainId(images);
  }

  if (res.length === 0) {
    res = images.filter(Boolean).map(mapFile);
  }

  return res;
}

export function getDefinedValues(values) {
  for (const [key, value] of Object.entries(values)) {
    if (typeof value === "undefined") delete values[key];
  }

  return values;
}

function getFileExtension(key) {
  if (!key || typeof key !== "string" || key.length == 0) return false;
  const splitted = key.split(/\./);
  return splitted[splitted.length - 1];
}

export function isPsdByKey(key) {
  const extension = getFileExtension(key);
  return (extension || "").trim().toLowerCase() === "psd";
}
