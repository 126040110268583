import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { GET_ETSY_TASK_SUBMISSION } from "../../graphql/queries";

import { DownloadOutlined } from "@ant-design/icons";
import {
  Heading,
  Loading,
  Modal,
  Stack,
  SkeletonThumbnail,
} from "@shopify/polaris";
import moment from "moment";
import styled from "styled-components";
import artworkPreviewImg from "../../assets/images/artwork-preview.png";
import { arrInvalid, handleError } from "../../helper";
import SuspenseComp from "../shared/SuspenseComp";
import { useDesignCtx } from "./Layout";

import ImageGallery from "react-image-gallery";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";

// https://dashboard.merchbridge.com/artwork_preview/ksoXjJnxof?token=a18039f0a3292f55d81e4f909026dbe6

const Layout = React.lazy(() => import("./Layout"));
const ErrorPage = React.lazy(() => import("./ErrorPage"));
const Actions = React.lazy(() => import("./Actions"));

export default function Index() {
  const { id } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("token");

  const { data, loading, error } = useQuery(GET_ETSY_TASK_SUBMISSION, {
    skip: !id || !token,
    fetchPolicy: "cache-and-network",
    variables: {
      taskID: id,
      token,
    },
  });

  React.useLayoutEffect(() => {
    const appFrameMain = document.getElementById("AppFrameMain");
    if (appFrameMain) {
      appFrameMain.style.maxWidth = "1000px";
      appFrameMain.style.margin = "0 auto";
    }

    const frameMain = document.querySelector(".Polaris-Frame");
    if (frameMain) {
      frameMain.style.backgroundColor = "#fff";
    }

    return () => {
      appFrameMain.style.maxWidth = "unset";
      appFrameMain.style.margin = "unset";
    };
  }, []);

  const result = get(data, "getEtsyTaskSubmission");
  let designs = get(result, "taskSubmission.designs");
  let order = get(result, "order");
  let store = get(result, "store");
  let personalized = get(result, "personalized");
  const taskStatus = get(result, "taskStatus");
  const isDigital = get(result, "isDigital");

  designs = (designs || [])
    .filter((item) => !!get(item, "file.id"))
    .map((item) => item.file);

  if (!id || !token) return null;

  const loadingMarkup = loading && <Loading />;

  // is invalid: designs, order is empty
  const invalidToken = arrInvalid(designs) || !order;

  return (
    <SuspenseComp>
      {/* <MyGallery /> */}
      <Layout
        store={store}
        taskStatus={taskStatus}
        isDoingOrReject={isDoingOrReject(taskStatus)}
        invalidToken={invalidToken}
      >
        <Wrapper>
          {loadingMarkup}
          <StatusComp taskStatus={taskStatus} />
          {loading ? (
            <SkeletonPagePolaris />
          ) : error ? (
            <div>Error: {handleError(error?.toString())}</div>
          ) : invalidToken ? (
            <ErrorComp />
          ) : isDoingOrReject(taskStatus) ? (
            <DesignProcess />
          ) : (
            <Stack vertical>
              <div className="left">
                <DesignItems designs={designs} isDigital={isDigital} />

                {isReviewOrDone(taskStatus) ? (
                  isDigital && <DownloadFiles designs={designs} />
                ) : (
                  <ActionsComp
                    taskID={id}
                    token={token}
                    isDigital={isDigital}
                  />
                )}
              </div>
              <div className="right">
                <OrderInfo order={order} personalized={personalized} />
              </div>
            </Stack>
          )}
        </Wrapper>
      </Layout>
    </SuspenseComp>
  );
}

const DownloadFiles = ({ designs }) => {
  const { status } = useDesignCtx();
  if (!status || arrInvalid(designs)) return null;

  return (
    <div style={{ marginTop: "3rem" }}>
      <Heading element="h3" children="Download your artwork(s)" />
      <Stack>
        {designs.map((item, index) => (
          <div key={`item-${index}`}>
            <a href={item.url || item.thumbnailUrl} download target="_blank">
              <DownloadOutlined />
              <span style={{ marginLeft: "1rem" }}>{item.name}</span>
            </a>
          </div>
        ))}
      </Stack>
    </div>
  );
};

const ErrorComp = () => {
  return (
    <React.Suspense fallback={null}>
      <ErrorPage />
    </React.Suspense>
  );
};

const ActionsComp = ({ taskID, token, isDigital }) => {
  const { status } = useDesignCtx();
  if (!!status) return null;

  return (
    <React.Suspense fallback={null}>
      <Actions taskID={taskID} token={token} isDigital={isDigital} />
    </React.Suspense>
  );
};

const StatusComp = ({ taskStatus }) => {
  const { setStatus } = useDesignCtx();
  React.useEffect(() => {
    if (taskStatus && isReviewOrDone(taskStatus)) {
      setStatus("approval");
    }
  }, [taskStatus]);

  return null;
};

const DesignItems = ({ designs, isDigital }) => {
  const [src, setSrc] = React.useState("");

  const handleClick = (src) => () => {
    setSrc(src);
  };

  const modalRef = React.useRef(null);
  React.useLayoutEffect(() => {
    if (!modalRef.current) return;
    const dialog = modalRef.current.closest(".Polaris-Modal-Dialog__Modal");
    if (dialog) {
      dialog.style.maxWidth = "calc(100vw - 20px)";
      dialog.style.width = "calc(100vw - 20px)";
      dialog.style.maxHeight = "calc(100vh - 20px)";
      dialog.style.height = "calc(100vh - 20px)";
      dialog.style.overflow = "scroll";
    }
  });
  return (
    <>
      {/* <Carousel
        arrows
        customPaging={(i) => (
          <a>
            <img src={getSrc(designs[i])} />
          </a>
        )}
        // dots={false}
        
      >
        {designs.map((item, index) => {
          return (
            <DesignItem
              key={`design-${index}`}
              design={item}
              onClickImage={handleClick(getSrc(item))}
              isDigital={isDigital}
            />
          );
        })}
      </Carousel> */}
      <MyGallery designs={designs} />

      <Modal open={!!src} onClose={() => handleClick("")()} large>
        <div ref={modalRef}>
          <img src={src} alt="image popup" />
        </div>
      </Modal>
    </>
  );
};

const MyGallery = ({ designs }) => {
  const [loading, setLoading] = React.useState(true);
  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    let firstItem = null;
    const images = (designs || []).map((item) => {
      const original = getSrc(item, true);
      if (!firstItem) firstItem = original;
      const thumbnail = getSrc(item);
      return { original, thumbnail };
    });

    const img = new Image();
    img.src = firstItem;
    img.onload = () => {
      setLoading(false);
    };
    setImages(images);

    return () => {
      setImages([]);
    };
  }, [designs]);
  return loading ? (
    <SkeletonThumbnail />
  ) : (
    <ImageGallery items={images} showPlayButton={false} />
  );
};

const DesignItem = ({ design, onClickImage, isDigital }) => {
  const src = getSrc(design);
  const onClickImageFn = onClickImage ? onClickImage : () => {};
  return (
    <div className="design-wrap">
      {isDigital && !!src && (
        <a
          class="Polaris-Button Polaris-Button--plain download-design"
          download
          target="_blank"
          href={src}
        >
          <span class="Polaris-Button__Content">
            <span class="Polaris-Button__Icon">
              <span
                role="img"
                aria-label="download"
                class="anticon anticon-download"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="download"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                </svg>
              </span>
            </span>
            <span class="Polaris-Button__Text">Download</span>
          </span>
        </a>
      )}

      <img
        src={src}
        alt={""}
        style={{ cursor: "zoom-in" }}
        onClick={onClickImageFn}
      />
    </div>
  );
};

function OrderInfo({ order, personalized }) {
  const originId = get(order, "originId");
  const shippingAddress = get(order, "shippingAddress");
  const createdAt = get(order, "createdAt");
  const quantity = get(order, "quantity");
  const { address, city, country, buyer } = getAddr(shippingAddress);
  const { title, images, mainImageId } = get(order, "product") || {};

  let newImages = (images || [])
    .filter((item) => get(item, "file.id"))
    .map((item) => item.file);
  let firstImage;
  if (mainImageId) {
    firstImage = newImages.find((item) => item.id === mainImageId);
  }

  if (!firstImage && newImages.length > 0) {
    firstImage = newImages[0];
  }

  const newFieldValues = formatPersonalized(personalized);

  return (
    <div className="order-wrap">
      <Stack vertical spacing="tight">
        <div>
          <Heading element="h2" children="Summary" />
          <div className="content">
            <div>
              <span>Order: </span>
              <span className="sp-value">#{originId}</span>
            </div>
            <div>
              <span>Order data: </span>
              <span className="sp-value">{moment(createdAt).format("ll")}</span>
            </div>
          </div>
        </div>
        <div>
          <Heading element="h2" children="Shipping address:" />
          <div className="content">
            <div>
              <span className="sp-value">{buyer}</span>
            </div>
            <span className="sp-value">
              {address}
              <br />
              {city}
              <br />
              {country}
            </span>
          </div>
        </div>

        <div>
          <Heading element="h2" children="Personalized: " />
          <div style={{ marginTop: 5, marginLeft: 5 }}>
            {newFieldValues.length > 0 ? (
              <ul className="field-wrap">
                {newFieldValues.map((item, index) => (
                  <li className="field-item" key={`field-${index}`}>
                    <b>
                      <i>{item.name}: </i>
                    </b>
                    <span className="sp-value">{item.value}</span>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>

        <div>
          <Heading element="h2" children="Product:" />
          <div className="product-wrap content">
            {firstImage?.url ? (
              <img
                className="product-image"
                src={firstImage.url}
                alt="product image"
              />
            ) : null}
            <div>
              <b className="sp-value">{title}</b>

              <div>
                <b>
                  <i>Qty: </i>
                </b>
                <span className="sp-value">{quantity}</span>
              </div>
            </div>
          </div>
        </div>
      </Stack>
    </div>
  );
}

function DesignProcess() {
  return (
    <div className="design-process-wrap">
      <Heading element="h2" children="We're Updating Your Artwork!" />
      <div>
        <img src={artworkPreviewImg} alt="artwork preview image" />
      </div>
      <span>
        Our design team is currently working on your customized artwork. Please
        wait for our next update, and thank you for your patience!
      </span>
    </div>
  );
}

// utils
const filterFieldValues = (fieldValues) => {
  return (fieldValues || [])
    .map((item) => ({
      name: item.name,
      value: (item?.value || "").replace(/[\{\}]/gi, "").replace(/\"\"/gi, ""),
    }))
    .filter((item) => !!item.name && !!item.value);
};

const formatPersonalized = (personalized) => {
  if (!personalized || typeof personalized !== "string") return [];

  return JSON.parse(personalized);
};

const isStatus = (status, target) => status.includes(target);
const isReviewOrDone = (status) => isStatus(["Need Review", "Done"], status);
const isDoingOrReject = (status) => isStatus(["Rejected", "Doing"], status);

function getAddr(addr) {
  const {
    address1,
    address2,
    city,
    country,
    postalCode,
    state,
    firstName,
    lastName,
  } = addr || {};
  const joinFn = (...args) => [...args].filter(Boolean).join(", ");

  const buyer = [firstName, lastName].filter(Boolean).join(" ");
  return {
    buyer,
    address: joinFn(address1, address2),
    city: joinFn(city, `${state || ""} ${postalCode || ""}`),
    country,
  };
}

function getSrc(item, isUrl) {
  if (!item) return null;
  if (isUrl) {
    if (item.url) return item.url;
    if (item.thumbnailUrl) return item.thumbnailUrl;
  } else {
    if (item.thumbnailUrl) return item.thumbnailUrl;
    if (item.url) return item.url;
  }
  return null;
}

const Wrapper = styled.div`
  // padding-bottom: 18rem;
  min-height: calc(100vh - 150px);

  .design-wrap {
    position: relative;

    .download-design {
      position: absolute;
      bottom: 20px;
      right: 24px;

      svg {
        fill: inherit;
      }

      &:hover svg {
        fill: #006fbb;
      }
    }
  }

  .design-process-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    margin-bottom: 8rem;
  }

  .actions {
    margin-top: 2rem;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .Polaris-ButtonGroup__Item {
      width: 100%;
      button {
        width: inherit;
      }
    }

    ul {
      font-size: 13px;
      margin-top: 0.75rem;
    }

    @media (min-width: 768px) {
      // margin-top: 160px;
      // padding-bottom: 100px;

      margin: 10px;
      // margin-top: 2rem;

      .Polaris-ButtonGroup__Item {
        width: unset;

        &:nth-child(2) {
          flex: 1;

          button {
            width: 100%;
          }
        }
      }
    }
  }

  .order-wrap {
    .content {
      margin: 0.5rem 0 0.5rem;
    }
  }

  @media (min-width: 1024px) {
    > .Polaris-Stack {
      flex-direction: row;
      column-gap: 1.6rem;

      > .Polaris-Stack__Item {
        display: block;
        width: calc(40% - 0.8rem);
      }

      > .Polaris-Stack__Item:first-child {
        display: block;
        width: calc(60% - 0.8rem);
      }

      .left {
        width: 100%;
      }

      .right {
        margin-top: 0;
        border: none;
      }
    }
  }

  .right {
    border-top: 1px solid #dfe3e8;
    padding-top: 5px;

    .field-wrap {
      padding-left: 20px;
    }

    .product-image {
      width: 100px;
      height: 100px;
      object-fit: cover;
      display: block;
      margin-top: 0.5rem;
      border: 1px solid #ddd;
    }

    .product-wrap {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      align-items: start;

      @media (min-width: 768px) {
        align-items: center;
        flex-direction: row;
      }
    }

    .sp-value {
      font-size: 14px;
    }
  }

  .left {
    width: 100%;

    .Polaris-SkeletonThumbnail {
      height: 35rem;
      width: 100%;
      margin: 0 auto;
    }

    .image-gallery-content,
    .image-gallery {
      background-color: #fff !important;
    }

    .image-gallery-fullscreen-button {
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    @media (min-width: 992px) {
      width: calc(100% - (10px * 2));
    }

    @media (min-width: 1024px) {
      width: calc(100% - (20px * 2));
      margin: -10px;
      margin-top: -5px;
    }

    .ant-carousel {
      .design-wrap {
        border-radius: 8px;
        overflow: hidden;
        height: max-content;
        padding: 10px;

        img {
          height: auto;
          max-width: 100%;
          object-fit: cover;
          display: block;
          border: 1px solid #ddd;
        }
      }

      .slick-dots {
        bottom: -70px;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: 1024px) {
          bottom: -130px;
        }

        li {
          width: 60px;
          height: 60px;
          margin-left: 7px;
          margin-right: 7px;
          border: 2px solid #ddd;

          @media (min-width: 1024px) {
            width: 120px;
            height: 120px;
          }

          &.slick-active {
            border-color: #202e78;
          }

          a img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            display: block;

            @media (min-width: 1024px) {
              width: 120px;
              height: 120px;
            }
          }
        }
      }
    }
  }
`;
