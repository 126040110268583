import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal, Spinner, Icon, Checkbox } from "@shopify/polaris";
import {
  ViewMajorMonotone,
  DeleteMajorMonotone,
  MaximizeMajorMonotone,
} from "@shopify/polaris-icons";
import styled from "styled-components";

import { ImageTypes } from "../../variable";
import { CustomLinkPolaris } from "../shared/CustomLinkPolaris";
import { ViewModalImage } from "../product/ViewModalImage";
import { isURLDriveLink, replaceSizeForDriveLink } from "../../helper";
import psdIcon from "../../assets/images/psd-icon.png";

export const PreviewFilePolaris = (props) => {
  const {
    file: fileProps,
    index,
    handleRemove,
    isUpdateDesign,
    dPName,

    isMainImg,
    hasMainImg,
    mainImage,
    setMainImage,
    hideBtnRemove,
    showTitle,
    fromIdeaTask,
    filesHaveFiles: filesHaveFilesProp = [],
  } = props;

  const [isHover, setIsHover] = useState(null);
  const [viewImg, setViewImg] = useState({});
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [filesHaveFiles, setFilesHaveFiles] = useState(filesHaveFilesProp);

  useEffect(() => {
    setLoading(true);
    if (fileProps) {
      const id = setTimeout(() => {
        setFile(fileProps);
        setLoading(false);
      }, 1000);
      setTimeoutId(id);
    }
  }, [fileProps]);

  useEffect(() => {
    if (filesHaveFilesProp?.length > 0) {
      setFilesHaveFiles(filesHaveFilesProp);
    }
  }, [filesHaveFilesProp]);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseOver = useCallback((index) => setIsHover(() => index), []);
  const handleMouseLeave = useCallback(() => setIsHover(() => null), []);

  let isTypeImage = true;
  let mimeType = file?.mimeType;
  if (!mimeType) {
    let newMineType = file?.name?.split(".");
    mimeType =
      newMineType?.length > 0 ? newMineType[newMineType.length - 1] : null;
  }

  if (mimeType) {
    if (!ImageTypes.includes(mimeType && mimeType.toLowerCase())) {
      isTypeImage = false;
    }
  }

  const TagAWrap = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const ImgWrap = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: "1rem",
    textAlign: "center",
  };

  let thumbnailUrl = file?.thumbnailUrl || "";
  if (thumbnailUrl.length > 0) {
    thumbnailUrl = replaceSizeForDriveLink(thumbnailUrl);
  }

  let originUrl = file
    ? file.thumbnail
      ? file.thumbnail
      : file.url
      ? file.url
      : null
    : null;

  const href = file && (file.url ? file.url : thumbnailUrl);

  const name = file ? file.name : "";
  const title = file ? `${name} (#${file.id})` : "";

  const handleViewImg = useCallback((id) => {
    return (e) => {
      if (e?.preventDefault) {
        e.preventDefault();
      }

      if (e?.stopPropagation) {
        e.stopPropagation();
      }

      setViewImg((prev) => {
        return { ...prev, [id]: !prev[id] };
      });
    };
  }, []);

  const onClose = useCallback((id) => {
    return () => {
      setViewImg((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    };
  }, []);

  const fileId = file?.id;
  const viewByFileId = viewImg[fileId];
  useEffect(() => {
    if (viewByFileId) {
      setFilesHaveFiles((prev) => {
        let newFiles = [...prev];
        const index = (newFiles || []).findIndex((i) => i?.file?.id === fileId);
        if (index > -1) {
          newFiles = [
            newFiles[index],
            ...newFiles.filter((_el, idx) => idx !== index),
          ];
        }

        return newFiles;
      });
    }
  }, [viewByFileId, fileId]);

  return (
    <Container
      className={`file-item ${isTypeImage ? "" : "non-image"}`}
      isMainImg={isMainImg}
    >
      {loading ? (
        <Spinner />
      ) : (
        file && (
          <>
            {hasMainImg && (
              <Checkbox
                checked={!!mainImage[file.id]}
                onChange={setMainImage}
              />
            )}
            <div
              className={`file_wrap ${isTypeImage ? "" : "non-image"}`}
              key={`file-${index}`}
              onMouseOver={() => handleMouseOver(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              {isUpdateDesign ? <label>{dPName}</label> : null}
              {!isTypeImage && (
                <div style={TagAWrap} className="psd-wrap">
                  <img src={psdIcon} alt="" className="psd-icon" />
                  <CustomLinkPolaris href={href}>
                    {file && file.name ? file.name : null}
                  </CustomLinkPolaris>
                </div>
              )}
              <div
                className="file_inner"
                style={{
                  backgroundImage: `url(${
                    thumbnailUrl ? thumbnailUrl : file.url
                  })`,
                }}
              />
              <div
                className={`actions_wrap ${isHover === index ? "visible" : ""}`}
                onClick={handleViewImg(file.id)}
                style={{ cursor: "pointer" }}
              >
                {!!isTypeImage ? (
                  <div className="view-btn">
                    <Button
                      plain
                      icon={ViewMajorMonotone}
                      onClick={handleViewImg(file.id)}
                    />
                  </div>
                ) : null}
                {!hideBtnRemove && (
                  <div>
                    <Button
                      plain
                      icon={DeleteMajorMonotone}
                      onClick={() => handleRemove(file && file.id)}
                    />
                  </div>
                )}
                {isUpdateDesign ? (
                  <div>
                    <CustomLinkPolaris href={originUrl}>
                      <Icon source={MaximizeMajorMonotone}></Icon>
                    </CustomLinkPolaris>
                  </div>
                ) : null}
              </div>
            </div>
            {showTitle && (
              <span className="file-name" title={name}>
                {name}
              </span>
            )}

            {fromIdeaTask ? (
              <ViewModalImage
                item={{ images: filesHaveFiles }}
                open={!!viewImg[file.id]}
                toggleShowModal={onClose(file.id)}
                large
              />
            ) : (
              <Modal
                open={!!viewImg[file.id]}
                onClose={() =>
                  setViewImg((prev) => ({
                    ...prev,
                    [file.id]: !prev[file.id],
                  }))
                }
                title={`File: ${title}`}
                large
                sectioned
              >
                {isTypeImage ? (
                  <div style={ImgWrap}>
                    <div className="preview-file">
                      <img
                        alt={""}
                        src={getFileURL(file)}
                        style={{
                          maxWidth: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div style={TagAWrap}>
                    <CustomLinkPolaris href={href}>
                      {file && file.name ? file.name : null}
                    </CustomLinkPolaris>
                  </div>
                )}
              </Modal>
            )}
          </>
        )
      )}
    </Container>
  );
};

function getFileURL(file) {
  let thumbnailUrl = file?.thumbnailUrl || "";
  let isDriveLink = isURLDriveLink(thumbnailUrl);

  let src = file && file.url ? file.url : thumbnailUrl ? thumbnailUrl : null;
  return isDriveLink ? replaceSizeForDriveLink(thumbnailUrl) : src;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: ${(props) => (props.isMainImg ? "2px solid #3f4eae" : "")};

  &.non-image {
    width: auto !important;
    height: auto !important;

    .file_wrap {
      display: flex;
      column-gap: 5px;
    }

    .file_inner {
      width: unset !important;
      height: unset !important;
      background: unset !important;
    }

    .psd-wrap {
      align-items: start !important;
    }

    .psd-icon {
      margin-right: 5px;
    }

    .actions_wrap {
      cursor: pointer;
      position: relative;
      visibility: visible;
      padding: 0;
      background: transparent;

      .Polaris-Icon {
        svg {
          fill: unset;
        }
      }
    }
  }

  .file_wrap {
    width: 100%;
    height: 28rem;
    object-fit: cover;
    border-radius: 3px;
    position: relative;
    box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    .file_inner {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
    &:hover {
      label {
        transform: translateY(5rem);
      }
    }
    label {
      font-size: 1.4rem;
      font-weight: 500;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: rgba(255, 255, 255, 0.65);
      padding: 0.5rem;
      font-weight: 500;
      transform: translateY(0);
      transition: 0.3s all ease-out;
      z-index: 10;
      text-align: center;
      display: inline-block;
    }
  }
  .actions_wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: start;
    flex-direction: row;
    column-gap: 1.5rem;
    visibility: hidden;
    background: linear-gradient(
      180deg,
      rgba(33, 43, 54, 0.55),
      hsla(0, 0%, 100%, 0)
    );
    .view-btn {
      .Polaris-Icon {
        height: 2.2rem;
        width: 2.2rem;
      }
    }
    .Polaris-Icon {
      svg {
        fill: hsla(0, 0%, 100%, 0.8);
      }
    }
    &.visible {
      visibility: visible;
    }
  }
  .Polaris-Link {
    word-break: break-all;
  }
`;
