import React, { useCallback, useState, useEffect } from "react";
import {
    Pagination,
    ButtonGroup,
    Button,
    Popover,
    ActionList,
    TextField,
} from "@shopify/polaris";
import styled from "styled-components";
import { customPaginate, formatter } from "../../helper";
import { useAppContext } from "../../context";

const Container = styled.div`
    min-width: ${(props) => (props.showTotal ? "900px" : "auto")};
    min-width: ${({ widthSmall }) => (widthSmall ? "auto" : "")};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${({ isMobile }) =>
        isMobile &&
        `min-width: auto;
        flex-direction: column; 
        align-items: start;
        justify-content: start;
        row-gap: 0.5rem;
        
        .info-pagination {
            width: 100% !important;
        }
    `}

    @media (max-width: 1200px) {
        padding-right: 1.6rem;
    }

    .Polaris-Pagination {
        margin-top: 0;
        margin-bottom: 0;
    }

    .custom-pagination {
        .Polaris-Button {
            padding-left: 0.6rem;
            padding-right: 0.6rem;

            .Polaris-Button__Text {
                font-size: 1.6rem;
            }
        }
    }

    .control-pagination {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .info-pagination {
        max-with: 40%;
        text-align: right;
    }

    @media (max-width: 768px) {
        .info_wrap {
            display: none;
        }

        .total_wrap {
            display: flex;
            flex-direction: column;
        }

        .info-pagination {
            width: 30%;
        }
    }

    .go-to-wrap {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        align-items: center;

        .Polaris-Connected {
            width: 8rem;
        }
    }
`;

const LIMITS = [10, 20, 30, 50, 100];

export const PaginationPolaris = ({
    aggregation: { page, totalPage, offset, limit, onChange, total },
    showTotal,
    baseCost,
    totalRevenue,
    totalQty,
    totalOrder,
    totalKpi,
    totalKpiAssignTask,
    gotoPage = false,
    size = "medium",
    widthSmall = false,
    maxPages = 5,
    totalMarkup,
    extraFooter = null
}) => {
    // Context
    const { isMobile } = useAppContext();
    // State
    const [active, setActive] = useState(false);
    const [limitState, setLimitState] = useState(limit);
    const [offsetState, setOffsetState] = useState(offset);
    const [gotoPageValue, setGotoPageValue] = useState("");
    const typingTimeoutRef = React.useRef(null);

    useEffect(() => {
        if (onChange) {
            onChange(offsetState, limitState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offsetState, limitState]);

    limit = typeof limit === "string" ? Number(limit) : limit;
    offset = typeof offset === "string" ? Number(offset) : offset;
    let start = offset + 1;
    let end = offset + limit;
    if (total < end) {
        end = total;
    }

    // Update limit
    const limitRef = React.useRef(limit);
    useEffect(() => {
        if (limitRef.current !== limit) {
            setLimitState(limit);

            limitRef.current = limit;
        }
    }, [limit])

    const result = customPaginate(total, page, limit, maxPages);
    let { pages } = result;

    const handleChangePage = useCallback(
        (page) => {
            setOffsetState((page - 1) * limit);
        },
        [limit]
    );

    const handleOffsetChange = useCallback((value) => {
        setOffsetState(value);
    }, []);

    const toggleActive = useCallback(() => setActive((open) => !open), []);

    // Markup
    const controlMarkup = (
        <Pagination
            label={`${page}/${totalPage}`}
            hasPrevious={page > 1}
            onPrevious={() => handleOffsetChange(offset - limit)}
            hasNext={page < totalPage}
            onNext={() => handleOffsetChange(offset + limit)}
        />
    );

    const labelMarkup = (
        <div className="custom-pagination">
            {isMobile ? (
                controlMarkup
            ) : (
                <ButtonGroup>
                    {!widthSmall && pages && pages.length >= maxPages && (
                        <Button
                            onClick={() => handleChangePage(1)}
                            children="<<"
                            disabled={page === 1}
                            size={size}
                        />
                    )}
                    <Button
                        onClick={() => handleChangePage(page - 1)}
                        children="<"
                        disabled={page === 1}
                        size={size}
                    />
                    {pages &&
                        pages.map((p, idx) => (
                            <Button
                                primary={page === p}
                                children={`${p}`}
                                key={`button-${idx}`}
                                onClick={() => handleChangePage(p)}
                                size={size}
                            />
                        ))}
                    <Button
                        onClick={() => handleChangePage(page + 1)}
                        children=">"
                        disabled={page === totalPage}
                        size={size}
                    />
                    {!widthSmall && pages && pages.length >= maxPages && (
                        <Button
                            onClick={() => handleChangePage(totalPage)}
                            children=">>"
                            disabled={page === totalPage}
                            size={size}
                        />
                    )}
                </ButtonGroup>
            )}
        </div>
    );

    const activatorMarkup = (
        <Button onClick={toggleActive} disclosure size={size}>
            {limitState} / page
        </Button>
    );

    if (!LIMITS.includes(limit)) {
        LIMITS.push(limit);
    }
    LIMITS.sort((a, b) => a - b);

    const options = LIMITS.map((item) => ({
        content: `${item} / page`,
        onAction: () => {
            setLimitState(item);
            setOffsetState(0);
        },
        active: item === limitState,
    }));

    const checkInputGoto = useCallback(
        (input) => {
            const parsed = input ? parseInt(input) : 1;
            return Math.max(1, Math.min(parsed, totalPage));
        },
        [totalPage]
    );

    const handleGotoChange = React.useCallback(
        (value) => {
            setGotoPageValue(value);
            const newValue = checkInputGoto(value);

            typingTimeoutRef.current && clearTimeout(typingTimeoutRef.current);
            typingTimeoutRef.current = setTimeout(() => {
                handleChangePage(newValue);
            }, 750);
        },
        [checkInputGoto, handleChangePage]
    );

    const gotoPageMarkup = gotoPage ? (
        <div className="go-to-wrap">
            <span>Go to</span>
            <TextField
                type="number"
                value={gotoPageValue}
                min={1}
                onChange={handleGotoChange}
            />
        </div>
    ) : null;

    return (
        <Container
            showTotal={showTotal}
            widthSmall={widthSmall}
            isMobile={isMobile}
        >
            <div className="control-pagination">
                {total ? labelMarkup : controlMarkup}
                {showTotal && (
                    <Popover
                        active={active}
                        onClose={toggleActive}
                        activator={activatorMarkup}
                        fullWidth
                    >
                        <ActionList items={options} />
                    </Popover>
                )}
                {gotoPageMarkup}
            </div>

            {showTotal && (
                <>
                    <span className="info-pagination">
                        <div className="info_wrap">
                            <strong>{start}</strong> to <strong>{end}</strong>{" "}
                            of <strong>{total}</strong>{" "}
                            {total > 1 ? "items" : "item"}
                            {totalOrder && totalQty ? (
                                <span>
                                    {" "}
                                    (<strong>{totalQty}</strong>{" "}
                                    {totalQty > 1 ? "units" : "unit"} /{" "}
                                    <strong>{totalOrder}</strong>{" "}
                                    {totalOrder > 1 ? "orders" : "order"}).
                                </span>
                            ) : (
                                "."
                            )}
                        </div>
                        <div className="total_wrap">
                            {totalKpi ? (
                                <span>
                                    {" "}
                                    Total done KPI: <strong>{totalKpi}</strong>
                                    {"."}
                                </span>
                            ) : null}
                            {totalKpiAssignTask ? (
                                <span>
                                    {" "}
                                    Total KPI:{" "}
                                    <strong>{totalKpiAssignTask}</strong>
                                    {"."}
                                </span>
                            ) : null}
                            {baseCost ? (
                                <span>
                                    {" "}
                                    Total base cost:{" "}
                                    <strong>
                                        {formatter.format(baseCost)}
                                    </strong>
                                    {"."}
                                </span>
                            ) : null}
                            {totalRevenue ? (
                                <span>
                                    {" "}
                                    Total revenue:{" "}
                                    <strong>
                                        {formatter.format(totalRevenue)}
                                    </strong>
                                    {"."}
                                </span>
                            ) : null}
                            {totalMarkup}
                        </div>
                        {extraFooter}
                    </span>
                </>
            )}
        </Container>
    );
};


export default PaginationPolaris;