import get from "lodash/get";
import React from "react";
import useTimeout from "./useTimeout";

const initialValue = {
  loading: false,
  data: null,
  error: null,
};

function useCustomQuery({ queryOptions = {}, path }) {
  const [state, setState] = React.useReducer(
    (p, s) => ({ ...p, ...s }),
    initialValue,
  );

  const [setStateTimeout] = useTimeout();
  const queryOptionsStr = JSON.stringify(queryOptions);
  React.useEffect(() => {
    async function getData() {
      const { skip, ...queryOptions } = JSON.parse(queryOptionsStr);
      const { __apolloClient__: client } = window;
      if (!client || skip) return;

      setState({ ...initialValue, loading: true });
      try {
        const res = await client.query({
          ...queryOptions,
          fetchPolicy: "no-cache",
        });

        setStateTimeout(() => {
          setState({ data: get(res, path), loading: false });
        }, 500);
      } catch (err) {
        setStateTimeout(() => {
          setState({ error: err?.toString(), loading: false });
        }, 500);
      }
    }
    getData();
  }, [queryOptionsStr, path]);

  return state;
}

export { useCustomQuery };
