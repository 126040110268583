import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Card, Loading, Page } from "@shopify/polaris";
import { gql } from "apollo-boost";
import _, { isEqual } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { SETTING_VARIABLES, TRACKING_STATUS_DAYS } from "../../constants";
import { AppContext, createContext } from "../../context";
import { GET_VARIABLES } from "../../graphql/queries";
import {
  arrInvalid,
  checkRole,
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  getTeamID,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import {
  COMMON_FILTER,
  MerchBridge,
  ORDER_STATUS,
  TEAM_ROLE,
  USER_ROLE,
} from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { PaginationPolaris } from "../shared/PaginationPolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import SuspenseComp from "../shared/SuspenseComp";
import { ToastContextProvider } from "../shared/ToastContext";
import { PageActionsPolaris } from "./actions/PageActionsPolaris";
import OrderAssigneeContext from "./context/OrderAssigneeContext";
import { FiltersOrderPolaris } from "./FiltersOrderPolaris";
import { useScreenOptions } from "./hooks/useScreenOptions";
import { OrdersDTPolaris } from "./OrdersDTPolaris";
import { ScreenOptionsOPolaris } from "./ScreenOptionsOPolaris";

const OrderFilter = React.lazy(() => import("./components/OrderFilter"));

const toSlug = (text) => {
  if (!text) {
    return "";
  }
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "_");
};

let convertOS = _.cloneDeep(ORDER_STATUS);
for (let [key, value] of Object.entries(convertOS)) {
  convertOS[key] = toSlug(value);
}

const ORDER_CONTEXT_NAME = "OrderContext";
const [OrderProvider, useOrderContext] = createContext(ORDER_CONTEXT_NAME);

const GET_ORDER_COUNT = gql`
  query orders($filter: OrderFilter) {
    orders(filter: $filter) {
      total
      aggregations
    }
  }
`;

const ORDER_BASE_FRAGMENT = gql`
  fragment ORDER_BASE_FRAGMENT on Order {
    id
    originId
    baseCost
    status
    quantity
    paused
    supplierPrice
    carrierPrice
    createdAt
    fulfillmentStatus
    ebayOrderId
    originalProductIsCampaign
    usingTaskBulkDesign
    usingProductDesign
    fulfillmentServiceShippingOption
    additionalItemShippingPrice
    additionalItemShippingCondition
    customer {
      firstName
      lastName
    }
    team {
      id
      name
    }
    fulfillmentOrders {
      id
      fulfillmentId
      fulfillmentSubmitID
      FulfillmentDesc
    }
    supplier {
      id
      firstName
      lastName
    }
    storeId
    store {
      id
      title
      platform
      owners {
        id
        role
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
    product {
      regularPrice
      id
      personalized
      mainImageId
      designCheckedByQC
      basesDesignCheckedByQC
      baseVariantsDesignCheckedByQC
      tags {
        id
        name
      }
      collections {
        id
        name
      }
      images {
        id
        productBaseId
        file {
          id
          thumbnailUrl
          url
          createdAt
        }
      }
      productBases {
        id
        title
        attributes {
          name
          options
        }
      }
    }
    productVariant {
      id
      productBaseVariant {
        id
        fulfillmentProductId
        fulfillmentSku
        attributes {
          name
          option
        }
        productBase {
          id
          title
          niceName
          hasAdditionalShipping
        }
      }
    }
    carrier {
      id
      name
      code
      trackingUrl
    }
    product {
      id
      title
      sku
      personalized
      designPositions {
        file {
          id
          url
          thumbnailUrl
        }
        designPosition {
          sorting
          id
          name
          description
          productBaseId
        }
      }
    }
    # tracking {
    #   id
    #   status
    #   code
    #   newCode
    #   trackingMoreId
    #   newCodeStatus
    #   submitted
    #   newCodeSubmitted
    #   newCodeTrackingMoreId
    #   submittedTrackingNote
    #   submittedNewCodeTrackingNote
    # }
    trackingCodes {
      id
      trackingCode
      status
      trackingServiceSlug
      trackingServiceId
      submittedToSaleChannel
      carrierCode
    }
    designTaskCreated
    hasResent
    printingDate
    fulfillmentId
    fulfillment {
      id
      name
      slug
    }
    revenue
    shippingCost
    orderGrand {
      grandTotal
      isUpdated
    }
    shippingRate
    originalProductID
    originalProduct {
      id
      title
      sku
    }
    taskStatus
    usedCalcCost
    sellerNote
    isFinalRevenue
  }
`;

const MAPPING_ORDER_FRAGMENT = gql`
  fragment MAPPING_ORDER_FRAGMENT on MappingOrder {
    productId
    variantId
    sku
    price
    shippingService
    note
    metaData
    personalized
    originOrder
  }
`;

export const ORDERS = gql`
  query orders($filter: OrderFilter) {
    orders(filter: $filter) {
      total
      aggregations
      nodes {
        ...ORDER_BASE_FRAGMENT
      }
    }
  }
  ${ORDER_BASE_FRAGMENT}
`;

const ORDERS_WITH_MAPPING = gql`
  query orders($filter: OrderFilter) {
    orders(filter: $filter) {
      total
      aggregations
      nodes {
        ...ORDER_BASE_FRAGMENT
        mappingOrder {
          ...MAPPING_ORDER_FRAGMENT
        }
      }
    }
  }
  ${ORDER_BASE_FRAGMENT}
  ${MAPPING_ORDER_FRAGMENT}
`;

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
  .Polaris-Tabs__DisclosureActivator:focus {
    box-shadow: none;
  }
  .pagination-header {
    overflow-x: scroll;
    padding: 1.6rem;
    border-bottom: 0.1rem solid var(--p-border-subdued, #dfe3e8);
  }
`;

const trackingStatusDays = Object.keys(TRACKING_STATUS_DAYS);
const KEY_DAY_10 = trackingStatusDays[0];
const KEY_DAY_12 = trackingStatusDays[1];

const DAY_10 = {
  range: {
    from: moment().subtract(10, "years").startOf("day"),
    to: moment().subtract(10, "days").endOf("day"),
  },
};

const DAY_12 = {
  range: {
    from: moment().subtract(10, "years").startOf("day"),
    to: moment().subtract(12, "days").endOf("day"),
  },
};

const FIELD_FILTER = [
  ...COMMON_FILTER,
  "designTaskCreated",
  "trackingStatus",
  "status",
  "fulfilmentId",
  "storeId",
  "supplierId",
  "carrierId",
  "productBaseId",
  "fulfillmentStatus",
  "personalized",
  "orderSource",
  "viewAs",
  "storeManagerId",
  "groupId",
  "teamId",
  "usingTaskBulkDesign",
  "usingProductDesign",
  "referringSite",
  "sourceName",
  "fulfillmentServiceShippingOption",
  "manualOrder",
  "orderHasNote",
  "tagIds",
  "collectionIds",
];

export const OrdersPolaris = (props) => {
  const { path } = props;
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);

  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };
    let pathName = history?.location.pathname;
    let initialSearch = null;
    if (pathName.includes("redirect")) {
      initialSearch = pathName.split("/").pop();
    }
    if (initialFilter) {
      if (initialFilter.collectionIds) {
        initialFilter.collectionIds = initialFilter.collectionIds
          .split(/\,/)
          .filter(Boolean);
      }
      if (initialFilter.tagIds) {
        initialFilter.tagIds = initialFilter.tagIds.split(/\,/).filter(Boolean);
      }

      if (initialFilter.orderSource) {
        initialFilter.orderSource = initialFilter.orderSource
          .split(/\,/)
          .filter(Boolean);
      }
      if (initialFilter.orderType) {
        if ("personalized" === initialFilter.orderType) {
          initialFilter.personalized = true;
        }
        if ("normal" === initialFilter.orderType) {
          initialFilter.personalized = false;
        }
      }
      if (initialFilter.designTaskCreated) {
        if ("assigned" === initialFilter.designTaskCreated) {
          initialFilter.designTaskCreated = true;
        }
        if ("unassigned" === initialFilter.designTaskCreated) {
          initialFilter.designTaskCreated = false;
        }
      }
      if (initialFilter.usingTaskBulkDesign) {
        if ("Yes" === initialFilter.usingTaskBulkDesign) {
          initialFilter.usingTaskBulkDesign = true;
        }
        if ("No" === initialFilter.usingTaskBulkDesign) {
          initialFilter.usingTaskBulkDesign = false;
        }
      }
      if (initialFilter.orderHasNote) {
        if ("Yes" === initialFilter.orderHasNote) {
          initialFilter.orderHasNote = true;
        }
        if ("No" === initialFilter.orderHasNote) {
          initialFilter.orderHasNote = false;
        }
      }
      if (initialFilter.manualOrder) {
        if ("Yes" === initialFilter.manualOrder) {
          initialFilter.manualOrder = true;
        }
        if ("No" === initialFilter.manualOrder) {
          initialFilter.manualOrder = false;
        }
      }

      if (initialFilter.usingProductDesign === "Yes") {
        initialFilter.usingProductDesign = true;
      }

      if (initialFilter.fulfilmentId === MerchBridge) {
        initialFilter.fulfilmentId = "";
      }

      if ([KEY_DAY_10, KEY_DAY_12].includes(initialFilter.trackingStatus)) {
        initialFilter.status = "Fulfilled";
      }
      if (initialSearch) {
        initialFilter.search = initialSearch;
      }

      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }

      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filter, setFilter] = useState({
    limit: Number(getCookie("perPageOrders")) || 20,
    offset: 0,
    trackingStatus: null,
    status: null,
    fulfilmentId: null,
    range: null,
    search: "",
    storeId: null,
    supplierId: null,
    carrierId: null,
    productBaseId: null,
    fulfillmentStatus: null,
    personalized: null,
    orderSource: null,
    viewAs: props.viewAs,
    storeManagerId: null,
    designTaskCreated: null,
    groupId: null,
    teamId: null,
    usingTaskBulkDesign: null,
    collectionIds: null,
    tagIds: null,
    usingProductDesign: null,
    readyToPrintTime: null,
    referringSite: null,
    sourceName: null,
    fulfillmentServiceShippingOption: null,
    orderHasNote: null,
    manualOrder: null,
    ...initFilter,
  });
  const [screenOptions, setScreenOptions] = useState([]);
  const [userId, setUserId] = useState(null);
  const [aggregations, setAggregations] = useState({});
  const [total, setTotal] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [updateCount, setUpdateCount] = useState(false);
  const [isChangeCount, setIsChangeCount] = useState(null);
  const [statusCount, setStatusCount] = useState([]);
  const [fixedColumn, setFixedColumn] = useState(true);
  const [orderNode, setOrderNode] = useState(ORDERS);
  const [hasPassOnHoldTime, setHasPassOnHoldTime] = useState(false);

  // Context
  const { store, currentUser } = useContext(AppContext);
  const userRole = _.get(currentUser, "roles", []);
  const teamRole = _.get(currentUser, "teamUser.role", null);
  const teamId = getTeamID(currentUser);
  const { isSeller, isAdministrator } = checkRole(currentUser);

  // Props
  const { viewAs: viewAsProp } = props;

  // Queries
  const variableKey = React.useMemo(
    () => (teamId ? `${teamId}${SETTING_VARIABLES.SOHT}` : null),
    [teamId],
  );

  const [getVariables] = useLazyQuery(GET_VARIABLES, {
    variables: {
      filter: {
        search: variableKey,
      },
    },
    onCompleted: (res) => {
      if (res?.variables?.length > 0) {
        const firtVal = res.variables[0];
        if (firtVal?.value) {
          setHasPassOnHoldTime(true);
        }
      }
    },
  });

  const { data, loading, error, refetch } = useQuery(orderNode, {
    variables: {
      filter: {
        ...filter,
        storeId: store ? store.id : filter.storeId,
        trackingStatus: [DAY_12.range, DAY_10.range].includes(filter.range)
          ? "notfound"
          : filter.trackingStatus,
      },
    },
    fetchPolicy: "no-cache",
    onCompleted: () => {
      let filterStatus = filter && filter.status;
      if (null !== filterStatus) {
        getOrders({
          variables: {
            filter: {
              ...filter,
              status: null,
            },
          },
        });
      }

      if (isSeller || isAdministrator) {
        getVariables();
      }
    },
  });

  const [getOrders, { data: dataLQ, loading: loadingQ }] =
    useLazyQuery(GET_ORDER_COUNT);

  // Variables
  let teamSellerLead = false;

  if (userRole && teamRole) {
    if (userRole.includes(USER_ROLE.Seller)) {
      teamSellerLead = [
        TEAM_ROLE.Admin,
        TEAM_ROLE.StoreManager,
        TEAM_ROLE.MarketplaceManager,
      ].includes(teamRole);
    }
  }
  const defaultScreenOptions = useScreenOptions(filter?.viewAs, filter?.status);
  // Get data
  useEffect(() => {
    let id = getCookie("userId");
    const newCookie =
      "Supplier" === viewAsProp
        ? `screenOption_Order_Supplier_${id}`
        : `screenOption_Order_${id}`;
    let options = getCookie(newCookie);

    const optionValues = defaultScreenOptions.map((i) => i.value);
    if (options) {
      options = options.split(",");
      options = options.filter((opt) => {
        const op = opt?.trim() || "";
        if (!op) return false;
        return optionValues.includes(opt);
      });
    } else {
      options = optionValues;
    }

    setScreenOptions(options);
    setUserId(() => id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAsProp, defaultScreenOptions]);

  useEffect(() => {
    if (dataLQ) {
      let newAgg = _.get(dataLQ, "orders.aggregations");
      let total = _.get(dataLQ, "orders.total");
      setAggregations(newAgg);
      setTotal(total);
    }
  }, [dataLQ]);

  useEffect(() => {
    let agg, total;
    if (data && data.orders) {
      if (data.orders.total) {
        total = data.orders.total;
      }
      if (data.orders.aggregations) {
        agg = { ...data.orders.aggregations };
        if (agg["in_production"]) {
          agg["in_production"] = {
            ...agg["in_production"],
            doc_count:
              _.get(agg, "pending_payment.doc_count", 0) +
              _.get(agg, "ready_to_print.doc_count", 0) +
              _.get(agg, "printing.doc_count", 0),
          };
        }
      }

      let newAgg =
        aggregations != null
          ? JSON.parse(JSON.stringify(aggregations))
          : aggregations;
      let arrStatusKeys = [];
      for (let [, value] of Object.entries(convertOS)) {
        arrStatusKeys.push(value);
      }

      if (newAgg && statusCount.length) {
        arrStatusKeys.forEach((i) => {
          if (statusCount.includes(i)) {
            if (newAgg[i] && newAgg[i].doc_count) {
              if (
                newAgg[i].doc_count !== data.orders.aggregations[i].doc_count &&
                0 !== data.orders.aggregations[i].doc_count
              ) {
                newAgg[i].doc_count = data.orders.aggregations[i].doc_count;
              }
            }
          }
        });
        setAggregations(newAgg);
      }

      if (!isUpdate) {
        setAggregations(agg);
        setTotal(total);
        setIsUpdate(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isChangeCount, statusCount]);

  useEffect(() => {
    let {
      offset,
      limit,
      personalized,
      status,
      designTaskCreated,
      range,
      usingTaskBulkDesign,
      collectionIds,
      tagIds,
      usingProductDesign,
      readyToPrintTime,
      orderHasNote,
      manualOrder,
      fulfilmentId,
      ...rest
    } = filter;
    let orderType = null;
    if (true === personalized) {
      orderType = "personalized";
    }
    if (false === personalized) {
      orderType = "normal";
    }
    if (true === designTaskCreated) {
      designTaskCreated = "assigned";
    }
    if (false === designTaskCreated) {
      designTaskCreated = "unassigned";
    }
    if (true === usingTaskBulkDesign) {
      usingTaskBulkDesign = "Yes";
    }
    if (false === usingTaskBulkDesign) {
      usingTaskBulkDesign = "No";
    }
    if (true === usingProductDesign) {
      usingProductDesign = "Yes";
    }
    if (true === orderHasNote) {
      orderHasNote = "Yes";
    }
    if (false === orderHasNote) {
      orderHasNote = "No";
    }
    if (true === manualOrder) {
      manualOrder = "Yes";
    }
    if (false === manualOrder) {
      manualOrder = "No";
    }

    if ("" === fulfilmentId) {
      fulfilmentId = MerchBridge;
    }

    if (!arrInvalid(tagIds)) {
      tagIds = tagIds.filter(Boolean).join(",");
    }

    if (!arrInvalid(collectionIds)) {
      collectionIds = collectionIds.filter(Boolean).join(",");
    }

    let paged = convertToPaged(limit, offset);
    let params = null;
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
      orderType,
      designTaskCreated,
      status,
      usingTaskBulkDesign,
      usingProductDesign,
      orderHasNote,
      manualOrder,
      fulfilmentId,
      tagIds,
      collectionIds,
    });

    history.push(`${history.location.pathname}?${params}`);
  }, [filter]);

  useEffect(() => {
    if (userId) {
      const newCookie =
        "Supplier" === viewAsProp
          ? `screenOption_Order_Supplier_${userId}`
          : `screenOption_Order_${userId}`;
      setCookie(newCookie, screenOptions, 7);
    }
  }, [userId, JSON.stringify(screenOptions), viewAsProp]);

  useEffect(() => {
    if (screenOptions?.length) {
      const hasMappingOrder = (screenOptions || []).find(
        (item) => item === "mappingOrder",
      );
      if (hasMappingOrder) {
        setOrderNode(ORDERS_WITH_MAPPING);
      } else {
        setOrderNode(ORDERS);
      }
    }
  }, [screenOptions]);

  const handleReload = useCallback(() => {
    setFilter((prevState) => ({
      ...prevState,
    }));
    setIsUpdate(false);
  }, []);

  const onChangeFilter = useCallback((value) => {
    let {
      search,
      fulfilmentId,
      storeId,
      productBaseId,
      carrierId,
      supplierId,
      trackingStatus,
      personalized,
      orderSource,
      designTaskCreated,
      storeManagerId,
      range,
      groupId,
      teamId,
      usingTaskBulkDesign,
      collectionIds,
      tagIds,
      usingProductDesign,
      readyToPrintTime,
      referringSite,
      sourceName,
      fulfillmentServiceShippingOption,
      orderHasNote,
      manualOrder,
    } = value;
    if ([KEY_DAY_10].includes(trackingStatus)) {
      range = DAY_10.range;
    }
    if ([KEY_DAY_12].includes(trackingStatus)) {
      range = DAY_12.range;
    }

    let varPrevState = null;
    const newOrderSource = orderSource?.length > 0 ? orderSource : null;

    setFilter((prevState) => {
      varPrevState = prevState;
      return {
        ...prevState,
        search,
        fulfilmentId,
        storeId,
        productBaseId,
        carrierId,
        supplierId,
        trackingStatus,
        personalized,
        orderSource: newOrderSource,
        designTaskCreated,
        storeManagerId,
        range,
        groupId,
        teamId,
        usingTaskBulkDesign,
        collectionIds,
        tagIds,
        usingProductDesign,
        readyToPrintTime,
        referringSite,
        sourceName,
        fulfillmentServiceShippingOption,
        orderHasNote,
        manualOrder,
      };
    });
    if (varPrevState) {
      if (
        !isEqual(varPrevState.search, search) ||
        !isEqual(varPrevState.fulfilmentId, fulfilmentId) ||
        !isEqual(varPrevState.storeId, storeId) ||
        !isEqual(varPrevState.productBaseId, productBaseId) ||
        !isEqual(varPrevState.carrierId, carrierId) ||
        !isEqual(varPrevState.supplierId, supplierId) ||
        !isEqual(varPrevState.trackingStatus, trackingStatus) ||
        !isEqual(varPrevState.personalized, personalized) ||
        !isEqual(varPrevState.orderSource, newOrderSource) ||
        !isEqual(varPrevState.designTaskCreated, designTaskCreated) ||
        !isEqual(varPrevState.storeManagerId, storeManagerId) ||
        !isEqual(varPrevState.range, range) ||
        !isEqual(varPrevState.groupId, groupId) ||
        !isEqual(varPrevState.teamId, teamId) ||
        !isEqual(varPrevState.usingTaskBulkDesign, usingTaskBulkDesign) ||
        !isEqual(varPrevState.collectionIds, collectionIds) ||
        !isEqual(varPrevState.tagIds, tagIds) ||
        !isEqual(varPrevState.usingProductDesign, usingProductDesign) ||
        !isEqual(varPrevState.readyToPrintTime, readyToPrintTime) ||
        !isEqual(varPrevState.referringSite, referringSite) ||
        !isEqual(varPrevState.sourceName, sourceName) ||
        !isEqual(varPrevState.orderHasNote, orderHasNote) ||
        !isEqual(varPrevState.manualOrder, manualOrder) ||
        !isEqual(
          varPrevState.fulfillmentServiceShippingOption,
          fulfillmentServiceShippingOption,
        )
      ) {
        setIsUpdate(false);
        setFilter((prev) => ({ ...prev, offset: 0 }));
      }
    }
  }, []);

  const onChangePH = useCallback((offset, limit) => {
    setFilter((prevState) => ({
      ...prevState,
      offset,
      limit,
    }));
    setCookie("perPageOrders", limit, 100);
  }, []);

  const loadingMarkup = loading && <Loading />;

  const newTotal = _.get(data, "orders.total", 0);
  const totalPage = Math.ceil(newTotal / filter.limit);
  const page = filter.offset / filter.limit + 1;
  const aggregation = {
    page,
    totalPage,
    offset: filter.offset,
    limit: filter.limit,
    onChange: onChangePH,
    total: newTotal,
  };

  return (
    <ToastContextProvider>
      <OrderProvider
        itemsChecked={itemsChecked}
        hasPassOnHoldTime={hasPassOnHoldTime}
      >
        <Container>
          {loadingMarkup}
          <div>
            <ScreenOptionsOPolaris
              options={defaultScreenOptions}
              defaultValue={screenOptions}
              onChange={(value) => setScreenOptions(value)}
              onChangeFixedColumn={(value) => setFixedColumn(value)}
              fixedColumn={fixedColumn}
            />
          </div>
          <Page
            title="All Orders"
            fullWidth
            primaryAction={
              <PageActionsPolaris
                {...props}
                filter={filter}
                KEY_DAY_10={KEY_DAY_10}
                KEY_DAY_12={KEY_DAY_12}
                data={data}
                currentUser={currentUser}
                refetch={refetch}
              />
            }
          >
            <Card sectioned>
              <SuspenseComp>
                <OrderFilter
                  itemsChecked={itemsChecked}
                  refetch={refetch}
                  reload={handleReload}
                  filter={filter}
                  onChange={onChangeFilter}
                  updateCount={(v) => {
                    setUpdateCount((prevState) => !prevState);
                    setIsChangeCount(v);
                  }}
                />
              </SuspenseComp>
            </Card>
            <Card>
              <FiltersOrderPolaris
                loading={loading || loadingQ}
                value={props.ordersFilter}
                defaultValue={filter.status || filter.fulfillmentStatus}
                total={total}
                aggregations={aggregations}
                onChange={(value) => {
                  let status = value.status;
                  if ("Ready To Print" === status) {
                    status = "Ready to Print";
                  }
                  setFilter((prevState) => ({
                    ...prevState,
                    status: [ORDER_STATUS.DesignRejected].includes(status)
                      ? null
                      : status,
                    fulfillmentStatus: [ORDER_STATUS.DesignRejected].includes(
                      status,
                    )
                      ? status
                      : null,
                    offset: 0,
                  }));
                }}
              />
              {newTotal ? (
                <div className="pagination-header">
                  <PaginationPolaris aggregation={aggregation} showTotal />
                </div>
              ) : null}
              {loading ? (
                <SkeletonPagePolaris />
              ) : (
                <>
                  {error ? (
                    <div>Error: {handleError(error.toString())}</div>
                  ) : (
                    <>
                      {data?.orders?.nodes?.length > 0 ? (
                        // <TableOrdersPolaris
                        <OrderAssigneeContext
                          data={data.orders.nodes}
                          screenOptions={screenOptions}
                        >
                          <OrdersDTPolaris
                            {...props}
                            data={data}
                            refetch={refetch}
                            filter={filter}
                            screenOptions={screenOptions}
                            dataSO={defaultScreenOptions}
                            userRole={userRole}
                            teamRole={teamRole}
                            onSelect={(value) => setItemsChecked(value)}
                            updateCount={updateCount}
                            convertOS={convertOS}
                            fixedColumn={fixedColumn}
                            changeStatusCount={(v) => {
                              setStatusCount(v);
                            }}
                            setFilter={(offset, limit) => {
                              setFilter((prevState) => ({
                                ...prevState,
                                offset,
                                limit,
                              }));
                              setCookie("perPageOrders", limit, 100);
                            }}
                          />
                        </OrderAssigneeContext>
                      ) : (
                        <EmptyStatePolaris />
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </Page>
        </Container>
      </OrderProvider>
    </ToastContextProvider>
  );
};

export { useOrderContext };
