import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import { debounce } from "lodash";

const Container = styled.div`
  .mce-content-body p {
    margin: 10px 0 !important;
  }
  .tox-statusbar__branding {
    display: none;
  }
`;

class Wysiwyg extends React.Component {
  state = {
    value: this.props.value,
    visible: false,
    useDebounce: this.props.useDebounce,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  typingTimeoutRef;
  onChange = (text) => {
    const { onChange } = this.props;
    this.setState(
      {
        value: text,
      },
      () => {
        this.typingTimeoutRef && clearTimeout(this.typingTimeoutRef);
        this.typingTimeoutRef = setTimeout(() => {
          onChange && onChange(text);
        }, 600);
      },
    );
  };

  render() {
    // const { placeholder } = this.props;
    const { disabled = false } = this.props;
    return (
      <Container>
        <Editor
          disabled={disabled}
          // initialValue={this.state.value}
          // apiKey="jis64hzhphtcfqj0nsu344y8o1e0z03pmstq388aoktv6s22"
          apiKey="vc1hxxwrt681e8mswthadp98wypznwo8zphuz16vvvq8dj7y"
          init={{
            height: 350,
            plugins:
              "print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons code",
            mobile: {
              plugins:
                "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons code",
            },
            menubar: "file edit view insert format tools table tc help",
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_css: "//www.tiny.cloud/css/codepen.min.css",
          }}
          value={this.state.value}
          onEditorChange={this.onChange}
          // onEditorChange={
          //   this.state.useDebounce
          //     ? debounce(this.onChange, 600)
          //     : this.onChange
          // }
        />
      </Container>
    );
  }
}

/*
 * PropType validation
 */
Wysiwyg.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  useDebounce: PropTypes.bool,
};

export default Wysiwyg;
