import React from "react";

export default function useTimeout() {
  const timeout = React.useRef(null);

  const set = React.useCallback((fn, ms = 500) => {
    timeout.current && window.clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      fn();
    }, ms);
  }, []);

  const clear = React.useCallback(() => {
    timeout.current && window.clearTimeout(timeout.current);
  }, []);

  React.useEffect(() => {
    return clear;
  }, []);

  return [set, clear];
}
