import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Loading, Page } from "@shopify/polaris";
import { gql } from "apollo-boost";
import isEqual from "lodash/isEqual";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  convertObjectToParams,
  convertStringToObject,
  convertToOffset,
  convertToPaged,
  getCookie,
  handleError,
  matchPathName,
  removeFieldWithoutFilter,
  setCookie,
} from "../../helper";
import history from "../../history";
import { COMMON_FILTER } from "../../variable";
import { EmptyStatePolaris } from "../shared/EmptyStatePolaris";
import { SkeletonPagePolaris } from "../shared/SkeletonPagePolaris";
import { StoresFilterPolaris } from "./StoresFilterPolaris";
import { TableStoresPolaris } from "./TableStoresPolaris";

const Container = styled.div`
  margin: -1rem;
  @media (min-width: 640px) {
    margin: -1.5rem;
  }
`;

export const LOAD_STORES = gql`
  query stores($filter: StoreFilter) {
    stores(filter: $filter) {
      total
      nodes {
        id
        team {
          id
          name
        }
        title
        domain
        platform
        apiKey
        suspended
        certificate
        owners {
          user {
            id
            firstName
            lastName
            email
            avatar {
              id
              thumbnailUrl
            }
          }
          store_id
          role
        }
        status
        usingFakeVariants
        group {
          id
          name
        }
      }
    }
  }
`;

const FIELD_FILTER = [...COMMON_FILTER, "status", "platform", "seller"];

export const ListStorePolaris = ({ path }) => {
  const isMatchPathName = useMemo(() => matchPathName(path), [path]);
  const initFilter = useMemo(() => {
    let initialFilter = {
      ...convertStringToObject(history.location.search),
    };

    if (initialFilter) {
      let limit = initialFilter.limit;
      limit = Number(limit);
      if (!limit) {
        limit = 10;
      }
      initialFilter.limit = limit;

      if (initialFilter.paged) {
        initialFilter.offset = convertToOffset(
          initialFilter.limit,
          Math.round(initialFilter.paged),
        );
      }
      initialFilter = removeFieldWithoutFilter(initialFilter, FIELD_FILTER);
    }
    return initialFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMatchPathName]);

  const [filters, setFilters] = useState({
    limit: Number(getCookie("perPageStores")) || 20,
    offset: 0,
    platformSelected: ["woocommerce", "shopify", "shopbase"],
    search: null,
    status: null,
    platform: null,
    seller: null,
    ...initFilter,
  });

  const { data, loading, error, refetch } = useQuery(LOAD_STORES, {
    variables: {
      filter: { ...filters },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let { offset, limit, platformSelected, ...rest } = filters;

    let params = null;
    let paged = convertToPaged(limit, offset);
    params = convertObjectToParams({
      limit,
      paged,
      ...rest,
    });
    history.push(`${history.location.pathname}?${params}`);
  }, [filters]);

  const handleFilterChange = React.useCallback(
    ({ search, status, platform, seller }) => {
      setFilters((p) => {
        if (
          !isEqual(p.search, search) ||
          !isEqual(p.status, status) ||
          !isEqual(p.platform, platform) ||
          !isEqual(p.seller, seller)
        ) {
          return {
            ...p,
            search,
            status,
            platform,
            seller,
            offset: 0,
          };
        }
        return p;
      });
    },
    [],
  );

  const loadingMarkup = loading && <Loading />;

  return (
    <Container>
      {loadingMarkup}
      <Page
        title="All Online Stores"
        fullWidth
        primaryAction={
          <Button primary onClick={() => history.push("/seller/store/add")}>
            New Store
          </Button>
        }
      >
        <Card sectioned>
          <StoresFilterPolaris
            filter={filters}
            isOnlineStore
            onChange={handleFilterChange}
          />
        </Card>
        <Card>
          {error && <div>Error: {handleError(error.toString())}</div>}
          {loading ? (
            <SkeletonPagePolaris />
          ) : data?.stores?.nodes?.length ? (
            <TableStoresPolaris
              data={data}
              refetch={refetch}
              filters={filters}
              fixedColumn
              setFilters={(offset, limit) => {
                setFilters((prevState) => ({
                  ...prevState,
                  offset,
                  limit,
                }));
                setCookie("perPageStores", limit, 100);
              }}
            />
          ) : (
            <EmptyStatePolaris />
          )}
        </Card>
      </Page>
    </Container>
  );
};
